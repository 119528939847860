import { useState , useEffect } from "react"
import Pagination from 'react-bootstrap-4-pagination';


const PaginationIndex = (props) => {
const [currentPage, setCurrentPage] = useState();

let paginationConfig = {
  totalPages: Math.ceil(props.totalPages),
  currentPage: currentPage,
  prevNext: true,
  size: "md",
  borderColor: '#ef7f1b',
  activeBorderColor: '#ef7f1b',
  activeBgColor: '#ef7f1b',
  activeColor: 'white',
  color: 'purple',
  disabledColor: 'green',
  circle: true,
  shadow: true,
  center: true,
  onClick: function (page) {
    setCurrentPage(page)
    props.handler(page)
  
  },
};
  
  return (
    <div>
      <Pagination   {...paginationConfig} />     
    </div>
  );
 };

 export default PaginationIndex
