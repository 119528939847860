import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { CommonService } from "../../../utils/commonService";
import { useDispatch, useSelector } from "react-redux";
import {
  callStakingContractMethod,
  callStakingContractSendMethod
} from "../../../redux/action/contract.action";
import { TIER_TYPE_ENUM } from '../../../constant';

import { toast } from "../../../Components/Toast/Toast";
import "./Privatestyle.scss";

const Setting = () => {
  const dispatch = useDispatch();
  const walletAddress = useSelector((state) =>
    state.connect.walletAddress ? state.connect.walletAddress : false
  );
  const redmarsDecimals = useSelector((state) => state.redmars.redmarsDecimals);
  const busdDecimals = useSelector((state) => state.redmars.busdDecimals);
  const [redmarsAddress, setRedmarsAddress] = useState("");
  const [rewardPercentage, setRewardPercentage] = useState(0);
  const [tierList, setTierList] = useState([]);



  useEffect(async () => {
    if (walletAddress) {
      getDetails();
    }
  }, [walletAddress]);

  const getDetails = async () => {
    let tierCounter = await dispatch(callStakingContractMethod('tierCounter', [], true));
    let _tierInfo = [];
    for (var i = 1; i <= tierCounter; i++) {
      let tierInfo = await dispatch(callStakingContractMethod('tierInfoMapping', [i], true));
      _tierInfo.push(tierInfo);
    }
    setTierList(_tierInfo);
    let redmarsAddress = await dispatch(callStakingContractMethod('redmarsAddress', [], true));
    setRedmarsAddress(redmarsAddress);

    let rewardPercentage = await dispatch(callStakingContractMethod('rewardPercentage', [], true));
    setRewardPercentage(rewardPercentage / 100);
  }



  const updateTierValues = async (index, value, type) => {
    let _tierList = tierList;
    if (type === "allocationAmount") {
      _tierList[index].allocationAmount = value;
    } else {
      _tierList[index].stakeAmount = value;
    }
    setTierList([..._tierList]);
  }

  //Function to update redmars address
  const updateRedmarsAddressHandler = async (e) => {
    e.preventDefault();
    let updateRedmarsAddress = await dispatch(callStakingContractSendMethod('updateRedmars', [redmarsAddress], true, walletAddress));
    if (updateRedmarsAddress && updateRedmarsAddress?.status === true) {
      toast.success("Redmars address updated successfully");
    }
  };

  const updateRewardPercentageHandler = async (e) => {
    e.preventDefault();
    let rewardPercentageUpdate = await dispatch(callStakingContractSendMethod('updateRewardPercentage', [rewardPercentage * 100], true, walletAddress));
    if (rewardPercentageUpdate && rewardPercentageUpdate?.status === true) {
      toast.success("Reward Percentage updated successfully");
    }
  };


  const updateTier = async (index) => {
    let tierValues = tierList[index];
    console.log('tierValues -- ', tierValues)
    let rewardPercentageUpdate = await dispatch(callStakingContractSendMethod('updateTier', [index + 1, tierValues.stakeAmount, tierValues.allocationAmount], true, walletAddress));
    if (rewardPercentageUpdate && rewardPercentageUpdate?.status === true) {
      toast.success("Tier updated successfully");
    }
  }

  return (
    <Container fluid>
      <div className="setting-wrapper">
        <Row className="settings-info">
          <Col md={6}>
            <div className="dashbordInfo">
              <h4>Tier List</h4>
              <Row>

                {tierList.map((item, index) => (
                  <Col md={6} className="stake-lane">
                    <div className="stake-list">
                      <h3>{TIER_TYPE_ENUM[index]}</h3>
                      <form className="form_area">
                        <Form.Group controlId="form_group">
                          {/* <Row className="px-4"> */}
                          <Col md={12} className="px-4">
                            <Form.Label className="required">
                              $RMARS Required
                               </Form.Label>
                            <Form.Control
                              type="text"
                              name="updateRedmarsss"
                              value={item.stakeAmount / redmarsDecimals}
                              autoComplete="off"
                              onChange={(e) => {
                                let result = CommonService.allowOnlyNumber(
                                  e.target.value
                                );
                                if (result == true) {
                                  updateTierValues(index, e.target.value * redmarsDecimals, 'stakeAmount');
                                }
                              }}
                              placeholder="Enter Redmars"
                              required
                            />
                          </Col>
                          <Col md={12} className="px-4 mt-2">
                            <Form.Label className="required">
                              Max. investment
                               </Form.Label>
                            <Form.Control
                              type="text" rewardPercentage
                              name="updateRedmars"
                              autoComplete="off"
                              value={item.allocationAmount / busdDecimals}
                              onChange={(e) => {
                                let result = CommonService.allowOnlyNumber(
                                  e.target.value
                                );
                                if (result == true) {
                                  updateTierValues(index, e.target.value * busdDecimals, 'allocationAmount');
                                }
                              }}
                              placeholder="Enter Allocation Amount"
                              required
                            />

                          </Col>

                          {/* </Row> */}
                          <Col md={12} className="text-right p-2 mt-2">
                            <Button
                              className="cus-btn"
                              title="Submit"
                              onClick={(e) => {
                                updateTier(index);
                              }}
                            >
                              Update
                  </Button>
                          </Col>
                        </Form.Group>
                      </form>
                    </div>

                  </Col>

                ))}
              </Row>
            </div>
          </Col>
          <Col md={6}>

            <Col md={12}>
              <div className="dashbordInfo">
                {/* <h4>Update Minimum Redmars</h4> */}
                <form className="form_area" onSubmit={updateRewardPercentageHandler}>
                  <Form.Group controlId="form_group">
                    <Form.Label className="required">
                      Update Reward Percentage
                  </Form.Label>
                    <Form.Control
                      type="text"
                      name="minRedmars"
                      value={rewardPercentage}
                      onChange={(e) => {
                        let result = CommonService.allowOnlyNumber(
                          e.target.value
                        );
                        if (result == true) {
                          setRewardPercentage(e.target.value);
                        }
                      }}
                      placeholder="Reward Percentage"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="buttomBtn">
                    <Button
                      className="cus-btn"
                      type="Submit"
                    >
                      Update
                  </Button>
                  </Form.Group>
                </form>
              </div>
            </Col>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Setting;
