import React, { useState, useEffect } from "react";
import "./Home.scss";
import RequestPopup from "../../../Components/RequestPopup/RequestPopup";
import partner from '../../../Assets/images/appIcon.png';
//import Chart from "../../../Components/Chart/Chart";
import Container from "react-bootstrap/Container";
import {
  Row,
  Col,
  Button,
  Tab,
  Tabs,
  Form,
  Modal,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import bannerimg from "../../../Assets/images/banner.png";
import Buttoncustom from "../../../Components/Buttoncustom/Buttoncustom";
import about from "../../../Assets/images/about.png";
import MainHeading from "../../../Components/MainHeading/MainHeading";
import Slider from "../../../Components/Slider/Slider";
import graph from "../../../Assets/images/Graph.png";
import leftone from "../../../Assets/images/pathleft1.svg";
import lefttwo from "../../../Assets/images/pathleft2.svg";
import leftthree from "../../../Assets/images/pathleft3.svg";
import rightone from "../../../Assets/images/pathright1.svg";
import righttwo from "../../../Assets/images/pathright2.svg";
import rightthree from "../../../Assets/images/pathright3.svg";
import whitePaperDoc from "../../../Assets/whitePaper/redmarsWhitePaper.pdf";
import RedmarsAuditReport from "../../../Assets/whitePaper/redmarsAuditReport.pdf";
import arrow from "../../../Assets/images/right-arrow.png";
import Footer from "../../../Components/Footer/Footer";
import Stakemodal from "./Stakemodal";
import fb from "../../../Assets/images/fb.png";
import twitter from "../../../Assets/images/twitter.png";
import instagram from "../../../Assets/images/instagram.png";
import linkedin from "../../../Assets/images/linkedin.png";
import telegram from "../../../Assets/images/telegram.png";
import chainlink from "../../../Assets/images/chainlink.svg";
import tron from "../../../Assets/images/TronPad_white.png";
import bluezilla from "../../../Assets/images/bluezilla-dark.png";
import { Link } from "react-router-dom";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { CommonService } from "../../../utils/commonService";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "../../../Components/Toast/Toast";
import { toCreateProjectRequest } from "../../../redux/action/user.action";
import { requestICO } from "../../../redux/action/contract.action";

const Home = (props) => {
  const dispatch = useDispatch();

  const [noOfToken, setNoOfToken] = useState("");
  const [tokenPrice, setTokenPrice] = useState("");
  const [duration, setDuration] = useState("");
  const [fcfsDuration, setFcfsDuration] = useState("");
  const [projectName, setProjectName] = useState();
  const [projectOwnerName, setProjectOwnerName] = useState();
  const [projectDescription, setProjectDescription] = useState();
  const redmarsDecimals = useSelector((state) => state.redmars.redmarsDecimals);
  const busdDecimals = useSelector((state) => state.redmars.busdDecimals);
  const [vestingTime, setVestingTime] = useState("");
  const walletAddress = useSelector((state) => state.connect.walletAddress);

  const [address, setAddress] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setShow(false);
    setNoOfToken("");
    setTokenPrice("");
    setAddress("");
    setDuration("");
    setFcfsDuration("");
    setVestingTime("");
  };
  const handleShow = () => setShow(true);

  const createRequestIco = async (e) => {
    e.preventDefault();
    if (!walletAddress) {
      return toast.error("Please connect to the wallet.");
    }
    if (props && props.redmarsBalance <= 0) {
      return toast.error("Insufficient Redmars Balance.");
    }
    let data = {};

    // // to check allowance
    let payload = {
      project_name: projectName,
      project_owner_name: projectOwnerName,
      project_description: projectDescription,
    };
    let backendApiRequest = await dispatch(toCreateProjectRequest(payload));
    if (backendApiRequest.data.error == true) {
      toast.error(backendApiRequest.data.message);
      return;
    } else {
      data.projectId = backendApiRequest.data.data.id;
    }
    data.type = "createIco";
    data.walletAddress = walletAddress;
    data.noOfToken = noOfToken;
    data.tokenPrice = CommonService.convertWithDecimal(
      tokenPrice,
      busdDecimals
    ); // token price multiplied with redmars decimals
    data.address = address; //  token address
    data.duration = duration; // in seconds
    data.fcfsDuration = fcfsDuration; // in seconds
    //vesting time
    data.vestingTime = vestingTime;

    let result = await dispatch(requestICO(data));
    if (result && result.status) {
      toast.success("Ico Request created successfully.");
      setShow(false);
      setProjectDescription("");
      setProjectOwnerName("");
      setProjectName("");
      setNoOfToken("");
      setTokenPrice("");
      setAddress("");
      setDuration("");
      setFcfsDuration("");
      setVestingTime("");
      props.callback();
      handleClose();
    }
  };

  useEffect(() => {}, []);

  const downloadFile = () => {
    window.open(whitePaperDoc, "_blank");
  };

  const downloadAuditFile = () => {
    window.open(RedmarsAuditReport, "_blank");
  };

  return (
    <>
      <Container fluid className="banner" id="section1">
        <Container className="custom-width">
          <Row>
            <Col md={12} className="banner-left">
              <h2>Wanna Touch Mars's Soil ?</h2>
              <p>
                We are aiming to touch mars’s soil by 2025 as well as <br />
                crypto’s highest benchmark that is to overcome bitcoin.
              </p>
              <>
                <div className="btn_sec d-flex ">
                  <Buttoncustom
                    buttontext="Download Whitepaper"
                    download="download_whitepaper"
                    onClick={downloadFile}
                    className=" banner_btn"
                  />
                  <Buttoncustom
                    className="mars_btn banner_btn"
                    buttontext="Audit Report"
                    download="Audit Report"
                    onClick={downloadAuditFile}
                  />
                  <a href="https://pancakeswap.finance/swap" target="_blank">
                    <Buttoncustom
                      className="mars_btn swap-btn banner_btn"
                      buttontext="Buy on Pancaksswap"
                    />
                  </a>
                </div>
                <div className="btn_sec d-flex ">
                  <Button
                    href="#"
                    className="connect-btn banner_btn c_reqst ido-btn"
                    onClick={handleShow}
                  >
                    Apply for IDO
                  </Button>

                  <Modal size="xl" show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Apply for IDO</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form
                        className="request-modal"
                        onSubmit={(e) => createRequestIco(e)}
                      >
                        <Row>
                          <Col lg={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="tokenNumbers"
                            >
                              <Form.Label>Project Name</Form.Label>
                              <Form.Control
                                type="text"
                                autoComplete="off"
                                placeholder="Enter project name"
                                value={projectName}
                                onChange={(e) => {
                                  setProjectName(e.target.value);
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="tokenNumbers"
                            >
                              <Form.Label>Project Owner Name</Form.Label>
                              <Form.Control
                                type="text"
                                autoComplete="off"
                                placeholder="Enter project owner name"
                                value={projectOwnerName}
                                onChange={(e) => {
                                  setProjectOwnerName(e.target.value);
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="tokenNumbers"
                            >
                              <Form.Label>Project Description</Form.Label>
                              <Form.Control
                                type="text"
                                autoComplete="off"
                                placeholder="Project Description"
                                value={projectDescription}
                                onChange={(e) =>
                                  setProjectDescription(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="tokenNumbers"
                            >
                              <Form.Label>Number of Tokens</Form.Label>
                              <Form.Control
                                type="text"
                                autoComplete="off"
                                placeholder="Enter no. of token"
                                value={noOfToken}
                                onChange={(e) => {
                                  let result = CommonService.allowOnlyNumber(
                                    e.target.value
                                  );
                                  if (result == true) {
                                    setNoOfToken(e.target.value);
                                  }
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3" controlId="tokenPrice">
                              <Form.Label>Price of Tokens(In BUSD)</Form.Label>
                              <Form.Control
                                type="text"
                                autoComplete="off"
                                placeholder="Token Price"
                                value={tokenPrice}
                                onChange={(e) => {
                                  let result = CommonService.allowOnlyNumber(
                                    e.target.value
                                  );
                                  if (result == true) {
                                    setTokenPrice(e.target.value);
                                  }
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3" controlId="tokenPrice">
                              <Form.Label>Duration(In Days)</Form.Label>
                              <Form.Control
                                type="text"
                                autoComplete="off"
                                placeholder="Total Duration"
                                value={
                                  duration
                                    ? CommonService.getDaysFromMilliSecond(
                                        duration
                                      )
                                    : null
                                }
                                onChange={(e) => {
                                  let result = CommonService.allowOnlyNumber(
                                    e.target.value
                                  );
                                  if (result == true) {
                                    setDuration(
                                      CommonService.getMilliseconds(
                                        e.target.value
                                      )
                                    );
                                  }
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3" controlId="tokenPrice">
                              <Form.Label>FCFS Duration(In Days)</Form.Label>
                              <Form.Control
                                type="text"
                                autoComplete="off"
                                placeholder="FCFS Duration"
                                value={
                                  fcfsDuration
                                    ? CommonService.getDaysFromMilliSecond(
                                        fcfsDuration
                                      )
                                    : null
                                }
                                onChange={(e) => {
                                  let result = CommonService.allowOnlyNumber(
                                    e.target.value
                                  );
                                  if (result == true) {
                                    setFcfsDuration(
                                      CommonService.getMilliseconds(
                                        e.target.value
                                      )
                                    );
                                  }
                                }}
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={6}>
                            <Form.Group className="mb-3" controlId="tokenAdd">
                              <Form.Label>Token Address</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Token Address"
                                autoComplete="off"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="vestingTime"
                            >
                              <Form.Label>Vesting Time(In Days)</Form.Label>{" "}
                              <span
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Vesting time will start after ICO ends"
                              >
                                <BsFillInfoCircleFill />
                              </span>
                              <Form.Control
                                type="text"
                                autoComplete="off"
                                placeholder="Vesting Time"
                                value={
                                  vestingTime
                                    ? CommonService.getDaysFromMilliSecond(
                                        vestingTime
                                      )
                                    : null
                                }
                                onChange={(e) => {
                                  let result = CommonService.allowOnlyNumber(
                                    e.target.value
                                  );
                                  if (result == true) {
                                    setVestingTime(
                                      CommonService.getMilliseconds(
                                        e.target.value
                                      )
                                    );
                                  }
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="tokenNumbers"
                            >
                              <Form.Label>Website link</Form.Label>
                              <Form.Control
                                type="text"
                                autoComplete="off"
                                placeholder="Enter Website link"
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="tokenNumbers"
                            >
                              <Form.Label>Whitepaper link</Form.Label>
                              <Form.Control
                                type="text"
                                autoComplete="off"
                                placeholder="Enter Whitepaper link"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="tokenNumbers"
                            >
                              <Form.Label>Audit Report link</Form.Label>
                              <Form.Control
                                type="text"
                                autoComplete="off"
                                placeholder="Enter Audit Report link"
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="tokenNumbers"
                            >
                              <Form.Label>Add Name</Form.Label>
                              <Form.Control
                                type="text"
                                autoComplete="off"
                                placeholder="Name"
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="tokenNumbers"
                            >
                              <Form.Label>Add Address</Form.Label>
                              <Form.Control
                                type="text"
                                autoComplete="off"
                                placeholder="Address"
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="tokenNumbers"
                            >
                              <Form.Label>Add Proof</Form.Label>
                              <Form.Control
                                type="file"
                                autoComplete="off"
                                placeholder="Add KYC"
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={12} className="text-right mt-2">
                            <Button
                              variant="primary"
                              type="submit"
                              className="cm_btn"
                            >
                              Submit
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Modal.Body>
                  </Modal>

                  <Buttoncustom
                    className="mars_btn orbit-btn banner_btn"
                    buttontext="Orbit"
                  />
                  <a
                    href="https://azbit.com/exchange/RMARS_USDT"
                    target="_blank"
                  >
                    <Buttoncustom
                      className="mars_btn azbit-btn banner_btn"
                      buttontext="Azbit"
                      download="Azbit"
                    />
                  </a>
                </div>
                {/* <h5>OUR PARTNERS</h5> */}
                <div className="partners">
                <h5>Our Partners</h5>
                  <div>
                
                    <img src={partner} />
              
                  </div>
                </div>
                <div className="social banner-social">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/Red-Mars-100778685729697/"
                        target="_blank"
                      >
                        <img src={fb} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://t.me/redmarscoinofficial"
                        target="_blank"
                      >
                        <img src={telegram} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/redmarsoffical"
                        target="_blank"
                      >
                        <img src={twitter} />
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://instagram.com/redmarscoinofficial?utm_medium=copy_link"
                        target="_blank"
                      >
                        <img src={instagram} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/red-mars"
                        target="_blank"
                      >
                        <img src={linkedin} />
                      </a>
                    </li>
                  </ul>
                </div>
              </>
            </Col>
            {/* <Col md={6} className="banner-right">
              <img src={bannerimg} alt="banner" className="bannerimg" />
            </Col> */}
          </Row>
        </Container>
      </Container>
      <Container fluid className="about" id="section2">
        <Container className="custom-width">
          <Row>
            <Col lg={6} md={12} className="block text-start">
              <MainHeading heading="About" text="REDMARS" />
              <p>
                REDMARS is a decentralized BSC Network token that aims to bring
                multiple aspects together to contribute our part to serve the
                humanity in terms of space technology and economy. We strongly
                believe in upcoming future’s shelter will be needed for life and
                MARS can be our next home. We will bring prosperity to REDMARS
                (RMARS) holders.
              </p>
              <p>
                We are committed to launch multiple projects on our community
                and our upcoming Launchpad, where fees and profits will be
                distributed among our holders over their lifetimes. We will
                establish an organization for research and development work
                related to space science & technology.
              </p>

              <p>
                {" "}
                We have multiple projects that will beneficial to our holders,
                including NFT marketplace (Related to Space Science &
                Technology), Donation/Charity platform, IDO launchpad, and
                Launch of sub-projects under the umbrella of REDMARS.
              </p>
              <p className="custom-margin">
                {" "}
                Our Upcoming projects will have unique concept and utility of
                our purpose to reach the Mars’s soil till 2025 as well as
                bringing up the profit for our holders. Our sub projects will
                also have multiple features like staking, farming and other
                innovative utilities.
              </p>
            </Col>
            <Col lg={6} md={12} className="block about-block">
              <img src={about} alt="about" className="aboutimg" />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className="slider" id="section3">
        <Container className="custom-width">
          <div className="single-slider">
            <div className="col_title text-center ">
              <h2 className="mb-0">
                We are very serious about crossing the milestone of <br />
                $2 in next 6 Months
              </h2>
            </div>
          </div>
          <Slider />
        </Container>
      </Container>

      {/* <Container fluid className="round" id="section4">
        <Container>
          <div className="col_title text-center ">
            <h2>Second Round Presale Successful Completed</h2>
            <p>
              PancakeSwap Listing on 24 October 2021 17:30 UTC with $0.03 Price
            </p>
            <Buttoncustom buttontext="Presale Completed" />
          </div>
        </Container>
      </Container> */}

      <Container fluid className="how_it_works" id="section5">
        <Row>
          <Col md={12} lg={12} className="text-center">
            <div className="cm_title text-white">
              <h2>How it Works</h2>
            </div>
          </Col>
          <Col md={12} lg={12}>
            <div className="swap-block d-flex">
              <Col md={12} lg={1} />
              <Col md={12} lg={2} className="swap-lane">
                <div className="swap-list">
                  <p>
                    User must have a Metamask account for running the
                    application on web
                  </p>
                </div>
                <span>
                  <img src={arrow} alt="" className="first_child" />
                </span>
              </Col>
              <Col md={12} lg={2} className="swap-lane">
                <div className="swap-list">
                  <p>
                    Click on connect button & it will connect account with your
                    Metamask
                  </p>
                </div>
                <span>
                  <img src={arrow} alt="" />
                </span>
              </Col>
              <Col md={12} lg={2} className="swap-lane">
                <div className="swap-list">
                  <p>
                    User must have a min 1000 Redmars token in order to use the
                    platform
                  </p>
                </div>
                <span>
                  <img src={arrow} alt="" />
                </span>
              </Col>
              <Col md={12} lg={2} className="swap-lane">
                <div className="swap-list">
                  <p>
                    Under Projects, user can click on “Buy Token” button to buy
                    some tokens from a certain ICO
                  </p>
                </div>
                <span>
                  <img src={arrow} alt="" />
                </span>
              </Col>
              <Col md={12} lg={2} className="swap-lane">
                <div className="swap-list">
                  <p>After the completion of ICO user will be able to claim</p>
                </div>
                <span>
                  <img src={arrow} alt="" className="last_child" />
                </span>
              </Col>
              <Col md={12} lg={1}></Col>
            </div>
          </Col>
        </Row>
      </Container>

      {/* <section className="presale" id="section7">
        <Container>
          <Row>
            <Col md={12} lg={12}>
              <div className="head_title text-center">
                <h2>
                  Presale <span>Details</span>
                </h2>
                <p>Presale Round one successfully completed</p>
              </div>
            </Col>
            <Col md={6} lg={6}>
              <div className="block">
                <ul>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" />
                      Presale Tokens: 30000000{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" />
                      Presale Price: $0.007 (approx as 1BNB=60000 RMARS)
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" />
                      PancakeSwap LIsting Price: $0.03
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" /> Presale Start
                      Date: 08 October 2021
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" />
                      Presale End Date : 23 October 2021
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={6} lg={6}>
              <div className="block">
                <ul>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" />
                      Presale Tokens: 30000000{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" />
                      Presale Price: $0.007 (approx as 1BNB=60000 RMARS)
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" />
                      PancakeSwap LIsting Price: $0.03
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" /> Presale Start
                      Date: 08 October 2021
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" />
                      Presale End Date : 23 October 2021
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={12} lg={12}>
              <div className="head_title_new text-center">
                <p>Presale Round Two</p>
              </div>
            </Col>

            <Col md={6} lg={6}>
              <div className="block">
                <ul>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" />
                      Presale Tokens: 30000000{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" />
                      Presale Price: $0.007 (approx as 1BNB=60000 RMARS)
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" />
                      PancakeSwap LIsting Price: $0.03
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" /> Presale Start
                      Date: 08 October 2021
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" />
                      Presale End Date: 23 October 2021
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={6} lg={6}>
              <div className="block">
                <ul>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" />
                      Presale Tokens: 30000000{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" />
                      Presale Price: $0.007 (approx as 1BNB=60000 RMARS)
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" />
                      PancakeSwap LIsting Price: $0.03
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" /> Presale Start
                      Date: 08 October 2021
                    </p>
                  </li>
                  <li>
                    <p>
                      <img src={check} alt="" className="mr-3" />
                      Presale End Date: 23 October 2021
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
      <Footer />
    </>
  );
};

export default Home;
