import React, { Component } from "react";
import { Routes, Route } from 'react-router-dom';
import { withRouter } from "react-router";
import Home from "../../Pages/PublicPages/Home/Home";
import { rootName } from "../../constant";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Project from "../../Pages/PublicPages/Project/Project";
import Staking from "../../Pages/PublicPages/Staking/Staking";


const PublicRoutes = () => {
  return (
    <div className="PublicArea__content">
      <Header />
      <Routes>
        <Route path="/">
          <Route index={true} element={<Home />}></Route>
          <Route path="Project" element={<Project />}></Route>
          <Route path="Staking" element={<Staking />} />
        </Route>
      </Routes>
    </div>
  );
};
export default PublicRoutes;