import {
  REDMARS_DECIMALS,
  BUSD_DECIMALS
} from "../constant/actionTypes";

const initialState = {
  redmarsDecimals: "",
  busdDecimals: ""
};

export default function redMars(state = initialState, { type, payload }) {
  switch (type) {
    case REDMARS_DECIMALS:
      return { ...state, redmarsDecimals: payload };
    case BUSD_DECIMALS:
      return { ...state, busdDecimals: payload };
    default:
      return state;
  }
}
