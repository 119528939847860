import { ContractService } from "../../services/ContractService"
import { CommonService } from "../../utils/commonService";
import { startLoading, stopLoading } from "./loading.action";
import { toast } from "../../Components/Toast/Toast";
import { REDMARS_TOKEN_ADDRESS, BUSD_ADDRESS } from '../../constant';
import {
    REDMARS_DECIMALS,
    BUSD_DECIMALS
} from "../constant/actionTypes";

export function callContractMethod(method, data = [], loading = true) {
    return async (dispatch, getState) => {
        try {
            // console.log('data - ', data)
            // console.log('method -> ', method)
            if (loading) dispatch(startLoading());
            const result = await ContractService.callContractGetMethod(method, data, "launchpad");
            if (loading) dispatch(stopLoading());
            return result;
        } catch (error) {
            console.log('error', method, error)
            if (loading) dispatch(stopLoading());
            return toast.error(CommonService.getError(error));
        }
    };
}

export function callStakingContractMethod(method, data = [], loading = true) {
    return async (dispatch, getState) => {
        try {
            // console.log('data - ', data)
            // console.log('method -> ', method)
            if (loading) dispatch(startLoading());
            const result = await ContractService.callContractGetMethod(method, data, "stake");
            if (loading) dispatch(stopLoading(false));
            return result;
        } catch (error) {
            console.log('error', method, error)
            if (loading) dispatch(stopLoading());
            return toast.error(CommonService.getError(error));
        }
    };
}

export function getDecimals() {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            let result = await ContractService.getDecimals(REDMARS_TOKEN_ADDRESS);
            dispatch({ type: REDMARS_DECIMALS, payload: 10 ** result });
            result = await ContractService.getDecimals(BUSD_ADDRESS);
            dispatch({ type: BUSD_DECIMALS, payload: 10 ** result });
            dispatch(stopLoading(false));
            return result
        }
        catch (error) {
            dispatch(stopLoading(false));
            return toast.error(CommonService.getError(error));
        }
    }
}

export function callStakingContractSendMethod(method, data = [], loading = true, walletAddress) {
    return async (dispatch, getState) => {
        try {
            console.log('data - ', data)
            console.log('method -> ', method)
            if (loading) dispatch(startLoading());
            const result = await ContractService.callContractSendMethod(method, data, walletAddress, 'stake');
            if (loading) dispatch(stopLoading(false));
            return result;
        } catch (error) {
            console.log('error', method, error)
            if (loading) dispatch(stopLoading());
            return toast.error(CommonService.getError(error));
        }
    };
}

export function callContractSendMethod(method, data = [], loading = true, walletAddress) {
    return async (dispatch, getState) => {
        try {
            // console.log('data - ', data)
            // console.log('method -> ', method)
            if (loading) dispatch(startLoading());
            const result = await ContractService.callContractSendMethod(method, data, walletAddress, 'launchpad');
            if (loading) dispatch(stopLoading(false));
            return result;
        } catch (error) {
            console.log('error', method, error)
            if (loading) dispatch(stopLoading());
            return toast.error(CommonService.getError(error));
        }
    };
}
export function stake(data) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            const result = await ContractService.stake(data);
            dispatch(stopLoading(false));
            return result;
        } catch (error) {
            dispatch(stopLoading());
            return toast.error(CommonService.getError(error));
        }
    };
}

export function unstake(data) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            const result = await ContractService.unstake(data);
            dispatch(stopLoading(false));
            return result;
        } catch (error) {
            dispatch(stopLoading());
            return toast.error(CommonService.getError(error));
        }
    };
}

export function getBalance(data) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            const result = await ContractService.getBalance(data);
            dispatch(stopLoading(false));
            return result;
        } catch (error) {
            dispatch(stopLoading());
            return toast.error(CommonService.getError(error));
        }
    };
}

export function requestICO(data) {
    
    console.log("***************************",data);
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            const result = await ContractService.requestICO(data);
            dispatch(stopLoading(false));
            return result;
        } catch (error) {
            dispatch(stopLoading());
            return toast.error(CommonService.getError(error));
        }
    };
}

export function approveICO(data) {
    return async (dispatch, getState) => {
        try {
            dispatch(startLoading());
            const result = await ContractService.approveICO(data);
            dispatch(stopLoading(false));
            return result;
        } catch (error) {
            dispatch(stopLoading());
            return toast.error(CommonService.getError(error));
        }
    };
}


