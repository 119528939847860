import React, { useEffect } from "react";
import { Routes, Route } from 'react-router-dom';
import { adminRootName } from "../../constant";
import AdminHeader from "../../Components/AdminHeader/AdminHeader";
import AdminSidebar from "../../Components/AdminSidebar/AdminSidebar";
import ProjectRequest from "../../Pages/AdminPages/PrivatePages/ProjectRequest";
import Setting from "../../Pages/AdminPages/PrivatePages/Setting";
import Staking from "../../Pages/AdminPages/PrivatePages/Staking";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toGetOwnerAddressAction } from "../../redux/action/admin.action";
import * as Session from "../../utils/session";
import { disconnectAddress } from "../../redux/action/connect.action";
const AdminRoutes = () => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const walletAddress = useSelector((state) =>
		state.connect.walletAddress ? state.connect.walletAddress : false
	);
	const adminType = useSelector((state) => state.connect.adminType);

	useEffect(() => {
		checkIfAuth();
	}, []);

	const checkIfAuth = async () => {
		// if (walletAddress !== false) {
		// 	let walletType = Session.getLoginSession();
		// 	let ownerAddress = await dispatch(toGetOwnerAddressAction(walletType));

		// 	if (ownerAddress.toLowerCase() !== walletAddress.toLowerCase()) {
		// 		await dispatch(disconnectAddress());
		// 		Session.removeLoginSession();
		// 		history.push(`${adminRootName}/login`);
		// 	}
		// } else {
		// 	await dispatch(disconnectAddress());
		// 	Session.removeLoginSession();
		// 	history.push(`${adminRootName}/login`);
		// }
	};
	return (
		<div className="PrivateArea__content">
			<div className="PrivateArea__contentIn">
				<div className="dashLeft">
					<AdminHeader />
					<AdminSidebar />
				</div>
				<div className="dashRight">
					<Routes >
						<Route path={`/`}>
							<Route index={true} element={<ProjectRequest />}></Route>
							{adminType === "launchpad" || adminType === "all" ? <>
								<Route path="project/requests" element={<ProjectRequest />}></Route>
								<Route path="settings" element={<Setting />}></Route>
							</> : ''}
							{adminType === "staking" || adminType === "all" ? <>
								<Route path="staking" element={<Staking />}></Route>
							</> : ''}
						</Route>
					</Routes>
					<div className="copyright text-center">
						<p className="text-white">
							©{new Date().getFullYear()} Redmars - All Rights Reserved.
						</p>
					</div>
				</div>
			</div>
		</div >
	);
};

export default AdminRoutes;
