import React from 'react'
import { Button } from 'react-bootstrap';
import './Buttoncustom.scss'
function Buttoncustom(props) {
  return (
    <>
      <Button className={`${props.className}`}
        //href="#"
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.buttontext}
      </Button>
    </>
  );
}

export default Buttoncustom
