import React, { useState, useEffect } from "react";
import "./AdminSidebar.scss";
import { adminRootName, rootName } from "../../constant";
import logo from "../../Assets/images/Logo.png";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const AdminSidebar = (props) => {
	const history = useNavigate();
	const walletAddress = useSelector((state) =>
		state.connect.walletAddress ? state.connect.walletAddress : false
	);
	const adminType = useSelector((state) => state.connect.adminType);

	useEffect(async () => {
		if (adminType === "launchpad" || adminType === "all" || adminType === "staking") { } else {
			history(`${adminRootName}/login`);
		}
	}, []);
	return (
		<div className="sidebar">
			<Link
				to={`${rootName}/dashboard`}
				className="d-flex justify-content-center logo_sidebar"
			>
				<img src={logo} alt="" />
			</Link>
			<ul className="linkList">

				{/* <li>
					<Link to={`${adminRootName}/dashboard`} className="">
						Dashboard
					</Link>
				</li> */}
				{adminType === "launchpad" || adminType === "all" ? <>
					<li>
						<NavLink to={`${adminRootName}/project/requests`} activeClassName="active-link">
							Project Requests
					</NavLink>
					</li>
					<li>
						<NavLink to={`${adminRootName}/settings`} activeClassName="active-link">
							Settings
					</NavLink>
					</li>
				</> : ''}

				{adminType === "staking" || adminType === "all" ? <>
					<li>
						<NavLink to={`${adminRootName}/staking`} activeClassName="active-link">
							Staking
					</NavLink>
					</li>
				</> : ''}

			</ul>
		</div>
	);
};

export default AdminSidebar;
