import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import "./RequestPopup.scss";
import * as Session from "../../utils/session";
import {
  createIcoRequestAction,
  toCheckAllowanceAction,
  toApproveAllowanceAction,
  toCreateProjectRequest,
} from "../../redux/action/user.action";
import { requestICO } from "../../redux/action/contract.action";
import { toast } from "../Toast/Toast";
import { CommonService } from "../../utils/commonService";
import { BsFillInfoCircleFill } from "react-icons/bs";

const MetaPopup = (props) => {
  const dispatch = useDispatch();
  const walletType = Session.getLoginSession();
  const walletAddress = useSelector((state) => state.connect.walletAddress);

  const [noOfToken, setNoOfToken] = useState("");
  const [tokenPrice, setTokenPrice] = useState("");
  const [duration, setDuration] = useState("");
  const [fcfsDuration, setFcfsDuration] = useState("");
  const [address, setAddress] = useState("");
  const [websitLink, setWebsitLink] = useState("");
  const [whitepaperLink, setWhitepaperLink] = useState("");
  const [auditReportLink, setAuditReportLink] = useState("");
  const [addName, setAddName] = useState("");
  const [addProof, setAddProof] = useState("");
  const [addaddress, setAddaddress] = useState("");
  const [email, setEmail] = useState("");
  const [phone_no, setPhoneNo] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectOwnerName, setProjectOwnerName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const redmarsDecimals = useSelector((state) => state.redmars.redmarsDecimals);
  const busdDecimals = useSelector((state) => state.redmars.busdDecimals);
  const [vestingTime, setVestingTime] = useState("");
  const [show, setShow] = useState(false);

  // images 64
  function encodeImageFileAsURL() {
    var filesSelected = document.getElementById("inputFileToLoad").files;
    if (filesSelected.length > 0) {
      var fileToLoad = filesSelected[0];

      var fileReader = new FileReader();

      fileReader.onload = function (fileLoadedEvent) {
        var srcData = fileLoadedEvent.target.result; // <--- data: base64
        setAddProof(srcData);
      };
      fileReader.readAsDataURL(fileToLoad);
    }
  }

  const handleClose = () => {
    setShow(false);
    setShow(false);
    setNoOfToken("");
    setTokenPrice("");
    setAddaddress("");
    setDuration("");
    setFcfsDuration("");
    setVestingTime("");
    setPhoneNo("");
  };
  const handleShow = () => setShow(true);

  const createRequestIco = async (e) => {
    e.preventDefault();
    if (!walletAddress) {
      return toast.error("Please connect to the wallet.");
    }
    if (props && props.redmarsBalance <= 0) {
      return toast.error("Insufficient Redmars Balance.");
    }
    let data = {};

    // // to check allowance
    let payload = {
      project_name: projectName,
      project_owner_name: projectOwnerName,
      project_description: projectDescription,
      website: websitLink,
      whitepaper: whitepaperLink,
      audit_report: auditReportLink,
      user_name: addName,
      user_email: email,
      user_phone_no: phone_no,
      user_postal_address: addaddress,
      user_id_proof: addProof,
    };
    let backendApiRequest = await dispatch(toCreateProjectRequest(payload));
    if (backendApiRequest.data.error == true) {
      toast.error(backendApiRequest.data.message);
      return;
    } else {
      data.projectId = backendApiRequest.data.data.id;
    }
    data.type = "createIco";
    data.walletAddress = walletAddress;
    data.noOfToken = noOfToken;
    data.tokenPrice = CommonService.convertWithDecimal(
      tokenPrice,
      busdDecimals
    ); // token price multiplied with redmars decimals
    data.address = address; //  token address
    data.duration = duration; // in seconds
    data.fcfsDuration = fcfsDuration; // in seconds
    //vesting time
    data.vestingTime = vestingTime;

    let result = await dispatch(requestICO(data));
    if (result && result.status) {
      toast.success("Ico Request created successfully.");
      setShow(false);
      setProjectDescription("");
      setProjectOwnerName("");
      setProjectName("");
      setNoOfToken("");
      setTokenPrice("");
      setAddress("");
      setDuration("");
      setFcfsDuration("");
      setVestingTime("");
      setWebsitLink("");
      setWhitepaperLink("");
      setAuditReportLink("");
      setAddName("");
      setAddProof("");
      setAddaddress("");
      setPhoneNo();
      props.callback();
      handleClose();
    }
  };

  return (
    <>
      <Button href="#" className="connect-btn c_reqst" onClick={handleShow}>
        Create Request
      </Button>
      <Modal size="xl" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Create Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="request-modal" onSubmit={(e) => createRequestIco(e)}>
            <Row>
              <Col lg={12}>
                <Form.Group className="mb-3" controlId="tokenNumbers">
                  <Form.Label>Project Name</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="Enter project name"
                    value={projectName}
                    onChange={(e) => {
                      setProjectName(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="tokenNumbers">
                  <Form.Label>Project Owner Name</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="Enter project owner name"
                    value={projectOwnerName}
                    onChange={(e) => {
                      setProjectOwnerName(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="tokenNumbers">
                  <Form.Label>Project Description</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="Project Description"
                    value={projectDescription}
                    onChange={(e) => setProjectDescription(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="tokenNumbers">
                  <Form.Label>Number of Tokens</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="Enter no. of token"
                    value={noOfToken}
                    onChange={(e) => {
                      let result = CommonService.allowOnlyNumber(
                        e.target.value
                      );
                      if (result == true) {
                        setNoOfToken(e.target.value);
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="tokenPrice">
                  <Form.Label>Price of Tokens(In BUSD)</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="Token Price"
                    value={tokenPrice}
                    onChange={(e) => {
                      let result = CommonService.allowOnlyNumber(
                        e.target.value
                      );
                      if (result == true) {
                        setTokenPrice(e.target.value);
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="tokenPrice">
                  <Form.Label>Duration(In Days)</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="Total Duration"
                    value={
                      duration
                        ? CommonService.getDaysFromMilliSecond(duration)
                        : null
                    }
                    onChange={(e) => {
                      let result = CommonService.allowOnlyNumber(
                        e.target.value
                      );
                      if (result == true) {
                        setDuration(
                          CommonService.getMilliseconds(e.target.value)
                        );
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="tokenPrice">
                  <Form.Label>FCFS Duration(In Days)</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="FCFS Duration"
                    value={
                      fcfsDuration
                        ? CommonService.getDaysFromMilliSecond(fcfsDuration)
                        : null
                    }
                    onChange={(e) => {
                      let result = CommonService.allowOnlyNumber(
                        e.target.value
                      );
                      if (result == true) {
                        setFcfsDuration(
                          CommonService.getMilliseconds(e.target.value)
                        );
                      }
                    }}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mb-3" controlId="tokenAdd">
                  <Form.Label>Token Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Token Address"
                    autoComplete="off"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="vestingTime">
                  <Form.Label>Vesting Time(In Days)</Form.Label>{" "}
                  <span
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Vesting time will start after ICO ends"
                  >
                    <BsFillInfoCircleFill />
                  </span>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="Vesting Time"
                    value={
                      vestingTime
                        ? CommonService.getDaysFromMilliSecond(vestingTime)
                        : null
                    }
                    onChange={(e) => {
                      let result = CommonService.allowOnlyNumber(
                        e.target.value
                      );
                      if (result == true) {
                        setVestingTime(
                          CommonService.getMilliseconds(e.target.value)
                        );
                      }
                    }}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="tokenNumbers">
                  <Form.Label>Website</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="Enter Website link"
                    value={websitLink}
                    onChange={(e) => setWebsitLink(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mb-3" controlId="tokenNumbers">
                  <Form.Label>Whitepaper</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="Enter Whitepaper link"
                    value={whitepaperLink}
                    onChange={(e) => setWhitepaperLink(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mb-3" controlId="tokenNumbers">
                  <Form.Label>Audit Report</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="Enter Audit Report link"
                    value={auditReportLink}
                    onChange={(e) => setAuditReportLink(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col lg={6}>
                <Form.Group className="mb-3" controlId="tokenNumbers">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="Name"
                    value={addName}
                    onChange={(e) => setAddName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="tokenNumbers">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    autoComplete="off"
                    placeholder="Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="tokenNumbers">
                  <Form.Label>Phone No</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="Address"
                    value={phone_no}
                    onChange={(e) => setPhoneNo(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="tokenNumbers">
                  <Form.Label>Postal Address</Form.Label>
                  <Form.Control
                    type="text"
                    autoComplete="off"
                    placeholder="Address"
                    value={addaddress}
                    onChange={(e) => setAddaddress(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group className="mb-3" controlId="tokenNumbers">
                  <Form.Label>Add Proof</Form.Label>
                  <Form.Control
                    type="file"
                    id="inputFileToLoad"
                    autoComplete="off"
                    placeholder="Add KYC"
                    onChange={() => encodeImageFileAsURL()}
                  />
                </Form.Group>
              </Col>
              <Col lg={12} className="text-right mt-2">
                <Button variant="primary" type="submit" className="cm_btn">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MetaPopup;
