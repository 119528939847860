import { METAMASK_ADDRESS, IS_LOGIN, ADMIN_TYPE } from "../action/connect.action";

const initialState = {
  walletAddress: "",
};

export default function connect(state = initialState, action = {}) {
  switch (action.type) {
    case METAMASK_ADDRESS:
      return { ...state, walletAddress: action.payload };
    case IS_LOGIN:
      return { ...state, isLogin: action.payload };
    case ADMIN_TYPE:
      return { ...state, adminType: action.payload };
    default:
      return state;
  }
}
