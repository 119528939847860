import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PublicRoutes from "./routes/PublicRoutes/PublicRoutes";

import AuthGuard from "./routes/Guards/AuthGuard";
import PrivateRoutes from "./routes/PrivateRoutes/PrivateRoutes";
import LoaderComponent from "./Components/LoaderCompoent/LoaderCompoent";
import { adminRootName, rootName } from "./constant";
import AdminAuthGuard from "./routes/Guards/AdminAuthGuard";
import AdminRoutes from "./routes/AdminRoutes/AdminRoutes";
import Login from "./Pages/AdminPages/Login/Login";
import { useDispatch, useSelector } from "react-redux";
import * as Session from "./utils/session";
import { connectWithWallet } from "./redux/action/connect.action";
import { getDecimals } from "./redux/action/contract.action";

const Application = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    onInit();
  }, []);
  const walletAddress = useSelector((state) =>
    state.connect.walletAddress ? state.connect.walletAddress : false
  );

  useEffect(async () => {
    if (walletAddress) {
      let walletType = Session.getLoginSession();
      await dispatch(connectWithWallet(walletType));
    }
  }, [walletAddress]);

  const onInit = async () => {
    //  let result = await dispatch(getNomicsDetails());
    //  if (result && result.status == 200) {
    //    sessionStorage.setItem("price", result.data.data[0].price);
    //  }
    await dispatch(getDecimals())

  };

  return (
    <React.Fragment>
      <LoaderComponent></LoaderComponent>
      <Router>
        <Routes>
          <Route path={`${adminRootName}/login`} element={<Login />} />
          <Route path={`${adminRootName}/*`} element={<AdminRoutes />} />
          <Route path={`${rootName}/auth`} element={<PrivateRoutes />} />
          <Route path={`/*`} element={<PublicRoutes />} />
        </Routes>
      </Router>
    </React.Fragment>
  );
};

export default Application;
