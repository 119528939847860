import React, { useEffect, useState } from "react";
import { CommonService } from "../../utils/commonService";
import "./Timer.scss";
import moment from "moment";
import { Row, Col, Button, Modal, Form, InputGroup, FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

export default function Timer(props) {

  const walletAddress = useSelector((state) =>
    state.connect.walletAddress);

  let timeLeftInterval;
  let item = props.data;
  const [time, setTime] = useState(moment.utc());

  useEffect(() => {
    clearInterval(timeLeftInterval);

    timeLeftInterval = setInterval(() => {
      setTime(moment.utc())
    }, 1000);

  }, []);

  useEffect(() => {
    return () => {
      clearInterval(timeLeftInterval);
    };
  }, []);


  const checkIcoStatus = (item) => {
    let totalTime = Number(item.ico_start_time) + Number(item.duration) + (Number(item?.no_of_tokens_for_sale) > Number(item?.no_of_tokens_sold) ? Number(item?.fcfs_duration) : 0);
    if (
      moment(totalTime * 1000).isAfter(moment.utc())
      && Number(item.no_of_tokens_for_sale) > Number(item.no_of_tokens_sold)
      && item?.is_active == 1
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkClaimStatus = (item) => {
    let totalTime = Number(item?.ico_start_time) + Number(item?.duration) + (Number(item?.no_of_tokens_for_sale) > Number(item?.no_of_tokens_sold) ? Number(item?.fcfs_duration) : 0) + Number(item?.vesting_time);
    if (
      moment(totalTime * 1000).isBefore(moment.utc()) && (item?.totalUserInvestmentBNB > 0 || item?.totalUserInvestmentRmars > 0) && item.user_claimed.length && item.user_claimed[0].is_claimed == 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkOwnerClaimButtonVisibilityStatus = (item) => {
    let icoOwnerAddress = item?.user_address; // user address as ico owner address
    let totalTime = Number(item.ico_start_time) + Number(item.duration) + (Number(item?.no_of_tokens_for_sale) > Number(item?.no_of_tokens_sold) ? Number(item?.fcfs_duration) : 0)
    if (
      icoOwnerAddress.toLowerCase() == walletAddress.toLowerCase() &&
      item?.is_owner_claimed == 0 &&
      item?.is_active == 1 &&
      moment(totalTime * 1000).isBefore(moment.utc()) &&
      Number(item?.no_of_tokens_for_sale) > Number(item?.no_of_tokens_sold)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      {checkIcoStatus(item) == true ? (
        <Button
          className="project_btn"
          type="button"
          onClick={(e) => props.showBuyModal(e, item)}
        >
          Buy Now
        </Button>
      ) : null}

      {checkClaimStatus(item) == true ? (
        <Button
          className="project_claimbtn mr-2"
          type="button"
          onClick={(e) => {
            props.claimUserToken(e, item?.project_id);
          }}
        >
          Claim
        </Button>
      ) : null}
      {checkOwnerClaimButtonVisibilityStatus(item) ==
        true ? (
        <Button
          className="project_claimbtn"
          type="button"
          onClick={(e) => {
            props.claimOwnerToken(e, item?.project_id);
          }}
        >
          Owner Claim
        </Button>
      ) : null}
    </div>
  );
}
