export const allUserTransactions = "investment";
 export const sendContactDetails = "common/contactus";
export const currenciesTicker = "currencies/ticker";
export const nomicApidetails = "nomics/currencies/ticker";

//endpoints for admin create request point 
export const approveKyc = "project/approve-kyc";
export const createProject = "project/create";

export const getAllIcoList = "project/request/list";
export const getUserInvestmentInAProject = "investment";

