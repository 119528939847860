import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Logo from "../../../Assets/images/Logo.png";
import MetaPopup from "../../../Components/MetaPopup/MetaPopup";
import { adminRootName } from "../../../constant";
import { toGetOwnerAddressAction } from "../../../redux/action/admin.action";
import * as Session from "../../../utils/session";
import "./Login.scss";
import { toast } from "../../../Components/Toast/Toast";
import AdminMetaPopup from "../../../Components/AdminMetaPopup/AdminMetaPopup";
import { setLoginStatus } from "../../../redux/action/connect.action";
import {
  callContractMethod,
  callStakingContractMethod
} from "../../../redux/action/contract.action";

const Login = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const walletAddress = useSelector((state) =>
    state.connect.walletAddress ? state.connect.walletAddress : false
  );
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const loginHandler = async () => {
    if (walletAddress !== false) {
      let stakingOwner = await dispatch(callStakingContractMethod('owner', [], true));
      let launchpadOwner = await dispatch(callContractMethod('owner', [], true));
      if (walletAddress.toLowerCase() === stakingOwner.toLowerCase() && walletAddress.toLowerCase() != launchpadOwner.toLowerCase()) {
        await dispatch(setLoginStatus(true, 'staking'));
        history(`${adminRootName}/project/requests`);
      } else if (walletAddress.toLowerCase() !== stakingOwner.toLowerCase() && walletAddress.toLowerCase() === launchpadOwner.toLowerCase()) {
        await dispatch(setLoginStatus(true, 'launchpad'));
        history(`${adminRootName}/project/requests`);
      } else if (walletAddress.toLowerCase() === stakingOwner.toLowerCase() && walletAddress.toLowerCase() === launchpadOwner.toLowerCase()) {
        await dispatch(setLoginStatus(true, 'all'));
        history(`${adminRootName}/project/requests`);
      } else {
        await dispatch(setLoginStatus(false, ''));
        toast.error("Invalid admin address")
      }
    } else {
      toast.error("Please connect with suitable wallet first.");
    }
  };

  return (
    <React.Fragment>
      <Container>
        <Row className="adjustble_vh">
          <Col lg="7" md={10} className="mx-auto">
            <div className="login_area">
              <div className="LogoBox text-center">
                <img src={Logo} alt="echain-logo" />
              </div>
              <AdminMetaPopup
                show={show}
                handleShow={handleShow}
                handleClose={handleClose}
              />

              <form>
                {walletAddress !== false ? (
                  <div className="main_walletAddress">
                    <span className="walletAddress_span">
                      {walletAddress}
                    </span>
                  </div>
                ) : null}
                <Form.Group className="buttomBtn col-12 text-center pt-4">
                  <Button
                    type="button"
                    className="connect-btn"
                    onClick={() => loginHandler()}
                  >
                    Login
                  </Button>
                </Form.Group>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Login;
