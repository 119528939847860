import React, { useState } from "react";
import { Modal, Button, Row, Col, Form, NavItem } from "react-bootstrap";
import { useDispatch } from "react-redux";
import logo from '../../../Assets/images/Logo.png'
import { toast } from "../../../Components/Toast/Toast";
import { approveKyc } from "../../../redux/action/user.action";
const Viewmodal = ({data}) => {

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


        const kyc = async () => {
            
            let approve_Kyc = await dispatch(approveKyc({databaseId : data?.id}))
            console.log('approve_Kyc', approve_Kyc)
            if(approve_Kyc?.status == 200) {
                toast.success("Kyc Approved")
            }

        }



    

    return (
        <>
            <Button href="#" className="cus-btn" onClick={handleShow}>
                View
            </Button>
            <Modal size="lg" show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>View Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="request-modal">
                        <Row>
                            <Col lg={4}>
                                <Form.Group className="mb-3" controlId="tokenNumbers">
                                    <Form.Label><h5>Website</h5></Form.Label>
                                    <p>{data?.website}</p>
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mb-3" controlId="tokenNumbers">
                                    <Form.Label><h5>White Paper</h5></Form.Label>
                                    <p>{data?.whitepaper}</p>
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mb-3" controlId="tokenNumbers">
                                    <Form.Label><h5>Audit Report</h5></Form.Label>
                                    <p>{data?.audit_report}</p>
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mb-3" controlId="tokenNumbers">
                                    <Form.Label> <h5>Name</h5></Form.Label>
                                    <p>{data?.user_name}</p>
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mb-3" controlId="tokenNumbers">
                                    <Form.Label> <h5>Email</h5></Form.Label>
                                    <p>{data?.user_email}</p>
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mb-3" controlId="tokenNumbers">
                                    <Form.Label><h5>Phone no.</h5> </Form.Label>
                                    <p>{data?.user_phone_no}</p>
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mb-3" controlId="tokenNumbers">
                                    <Form.Label> <h5>Postal Address</h5></Form.Label>
                                    <p>{data?.user_postal_address}</p>
                                </Form.Group>
                            </Col>
                            <Col lg={4}>
                                <Form.Group className="mb-3" controlId="tokenNumbers">
                                    <Form.Label> <h5>Id Proof</h5></Form.Label>
                                    <p className="logoimg"><img src={logo} /></p>
                                </Form.Group>
                            </Col>
                        </Row>
                  {
                      !data.kyc_approved ? 
                      <div className="text-right">
                            <Button href="#" className="cus-btn" onClick={() => kyc()}>
                                Approve KYC
            </Button>
                        </div> : ""
                  }      
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )

}
export default Viewmodal;
