import React, { useState, useEffect } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import Stakemodal from "../Home/Stakemodal";
import Buttoncustom from "../../../Components/Buttoncustom/Buttoncustom";
import { useSelector, useDispatch } from "react-redux";
import {
    callContractMethod,
    callStakingContractMethod,
    getBalance
} from "../../../redux/action/contract.action";
import { TIER_TYPE_ENUM, REDMARS_TOKEN_ADDRESS } from '../../../constant';
import moment from "moment";
import { tokenDetails } from "../../../CommonServices/CommonMethod.service";



const Staking = (props) => {

    const dispatch = useDispatch();

    const walletAddress = useSelector((state) =>
        state.connect.walletAddress ? state.connect.walletAddress : false
    );
    const redmarsDecimals = useSelector((state) => state.redmars.redmarsDecimals);
    const busdDecimals = useSelector((state) => state.redmars.busdDecimals);

    const [show, setShow] = useState(false);
    const [tierList, setTierList] = useState([]);
    const [userStakingInfo, setUserStakingInfo] = useState([]);
    const [currentTier, setCurrentTier] = useState(0);
    const [balance, setBalance] = useState(0);
    const [userAllocationValue, setUserAllocationValue] = useState(0);
    

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(async () => {
        getUserDetails();
    }, [walletAddress, redmarsDecimals]);

    useEffect(async () => {
        getDetails();
    }, []);

    const getUserDetails = async () => {
        if (walletAddress && redmarsDecimals) {
            let getStakerInfoMapping = await dispatch(callStakingContractMethod('getStakerInfoMapping', [walletAddress], true));
            let getTier = await dispatch(callStakingContractMethod('getTier', [getStakerInfoMapping._userStakedAmount], true));
            let balance = await dispatch(getBalance({ tokenAddress: REDMARS_TOKEN_ADDRESS, walletAddress }));
            setBalance(balance);
            setCurrentTier(getTier)
            setUserStakingInfo(getStakerInfoMapping)
            setUserAllocationValue(getStakerInfoMapping?._userAllocationValue)
        } else {
            setBalance(0);
            setCurrentTier(0)
            setUserStakingInfo(null)
            setUserAllocationValue(0)
        }
    }

    const getDetails = async () => {
        let tierCounter = await dispatch(callStakingContractMethod('tierCounter', [], true));
        let _tierInfo = [];
        for (var i = 1; i <= tierCounter; i++) {
            let tierInfo = await dispatch(callStakingContractMethod('tierInfoMapping', [i], true));
            _tierInfo.push(tierInfo);
        }
        setTierList(_tierInfo);
    }

    return (
        <>
            <Container fluid className=" project" id="staking">
                <Container className="custom-width">
                    <Row>
                        <Col md={12} lg={12} className="text-center">
                            <div className="cm_title text-white">
                                <h2>Staking</h2>
                                <p className="mb-5">Stake your $RMARS tokens for access to launchpad projects. No locking period on staking.</p>
                            </div>


                        </Col>

                        <Col md={12} lg={4} className="pd-0">
                            <div className="mb-3">
                                <Buttoncustom onClick={handleShow} className="stake-btn" buttontext="Stake / Unstake" disabled={walletAddress ? false : true} />
                                <Stakemodal getUserDetails={getUserDetails} balance={balance} currentTier={currentTier} show={show} onHide={handleClose} tierList={tierList} userStakingInfo={userStakingInfo} userAllocationValue = {userAllocationValue} />
                            </div>

                        </Col>
                        <Col md={12} lg={8} className="">
                            <div className="sidebar-card">
                                <div className="d-flex justify-content-between card-list">
                                    <div>
                                        <p className="card-title">$RMARS BALANCE</p>
                                        <div className="sidebar-card-body">
                                            <p>{(balance / redmarsDecimals).toFixed(4)}
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="card-title">AMOUNT OF $RMARS STAKED
                                        </p>
                                        <div className="sidebar-card-body">
                                            <p>{userStakingInfo ? userStakingInfo._userStakedAmount / redmarsDecimals : '-'}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="card-title">CURRENT TIER</p>
                                        <div className="sidebar-card-body">
                                            <p>{userStakingInfo && userStakingInfo._userStakedAmount > 0 ? currentTier ? TIER_TYPE_ENUM[currentTier - 1] : 'NONE' : 'NONE'}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="card-title">TIME STAKED</p>
                                        <div className="sidebar-card-body">
                                            <p>{userStakingInfo && userStakingInfo._stakeTimeStamp > 0 ? moment.unix(userStakingInfo._stakeTimeStamp).format("MM/DD/YYYY hh:mm A") : '-'}</p>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </Col>
                        {tierList.map((item, index) => (
                            <Col md={6} lg={3} className="stake-lane">
                                <div className="stake-list">
                                    <h3>{TIER_TYPE_ENUM[index]}</h3>
                                    <p className="subtitle">
                                        $RMARS Required
                                    </p>
                                    <p>{item.stakeAmount / redmarsDecimals}</p>
                                    <p className="subtitle">
                                        Max. investment in launchpad private sales
                                    </p>
                                    <p>$ {item.allocationAmount / busdDecimals}
                                    </p>
                                </div>

                            </Col>
                        ))}
                    </Row>
                </Container>
            </Container>
        </>
    )
}
export default Staking