import React from "react";
import "./App.scss";
import { Provider } from "react-redux";
import configureStore from "./redux/store";
import Application from "./Application";

let { store } = configureStore();

function App() {

  return (
    <Provider store={store}>
      <Application></Application>
    </Provider>
  );
}

export default App;
