import React, { useEffect, useState, Suspense } from "react";
import "./Project.scss";
import Container from "react-bootstrap/Container";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Button,
  Modal,
  Form, 
  InputGroup,
  FormControl,
} from "react-bootstrap";
import MainHeading from "../../../Components/MainHeading/MainHeading";
// import MetaPopup from "../../../Components/MetaPopup/MetaPopup";
import RequestPopup from "../../../Components/RequestPopup/RequestPopup";
import { CommonService } from "../../../utils/commonService";
import BigNumber from "big-number";
import { getBalance } from "../../../redux/action/contract.action";
import { TIER_TYPE_ENUM, REDMARS_TOKEN_ADDRESS } from "../../../constant";
import kycimg from '../../../Assets/images/kyc-icon-clr.svg'
import {
  calculateTokensAction,
  investTokenAction,
  redmarsTokenDetailsAction,
  redmarsTokenAction,
  claimUserTokenAction,
  toApproveAllowanceToBuyTokenAction,
  toCheckAllowanceToBuyTokenAction,
  toSetActivePagenoAction,
} from "../../../redux/action/user.action";
import * as Session from "../../../utils/session";
import { toast } from "../../../Components/Toast/Toast";
import Timer from "../../../Components/Timer/Timer";
import BtnActions from "../../../Components/Timer/btnActions";

import moment from "moment";
import { toClaimMoneyAction } from "../../../redux/action/admin.action";
import Pagination from "../../../Components/Pagination";
import { getIcoRequestList } from "../../../redux/action/admin.action";
import { useNavigate } from "react-router-dom";

const Project = () => {
  let globalList;
  const dispatch = useDispatch();
  const history = useNavigate();

  const walletAddress = useSelector((state) => state.connect.walletAddress);

  const walletType = Session.getLoginSession();
  const [redmarsDecimals, setRedmarsDecimals] = useState();
  const [redmarsSymbol, setRedmarsSymbol] = useState();

  const [projectInfo, setProjectInfo] = useState([]);
  const [show, setShow] = useState(false);
  const [bnb, setBnb] = useState("");
  const [projectData, setProjectData] = useState();
  const [calulatedToken, setCalculatedToken] = useState(0);
  const [redmarsTokenBalance, setRedmarsTokenBalance] = useState(0);
  //set token type state
  const [selectedTokenType, setTokenType] = useState(2); //default set is bnb
  const [pageLimit, setPageLimit] = useState(6);
  const [activePage, setActivePage] = useState(1);
  const [icoDetails, setIcoDetails] = useState();
  const busdDecimals = useSelector((state) => state.redmars.busdDecimals);
  const _redmarsDecimals = useSelector(
    (state) => state.redmars.redmarsDecimals
  );
  const [refreshTimer, setRefreshTimer] = useState(false);
  const [date, setDate] = useState(Date.now());

  const global_list_checker_interval = async () => {
    
    await clearInterval(globalList);
    globalList = setInterval(async function myTimer() {
      // if (walletAddress) {
      getTransactions();
      // }
    }, 5000);
  };

  useEffect(async () => {
    // global_list_checker_interval();
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(globalList);
    };
  }, []);

  const handleClose = () => {
    setShow(false);
    setBnb("");
    setCalculatedToken(0);
    setTokenType(0);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    addRemoveClass();
    if (_redmarsDecimals) {
      onIt();
      redmarsTokenDetails();
      // setInterval(function () {
      //   setDate(Date.now())

      // }, 1000);
    }
  }, [_redmarsDecimals]);

  useEffect(() => {
    // setRefreshTimer(!refreshTimer)
  }, [date]);
  useEffect(() => {
    if (bnb != undefined && bnb != "" && bnb != null) {
      convertedToken();
    }
  }, [selectedTokenType, bnb]);

  const redmarsTokenDetails = async () => {
    let result = await dispatch(redmarsTokenDetailsAction(walletType));
    // if (result) {
    let data = {};
    data.tokenAddress = result?.redMarsTokenAddress;
    data.walletAddress = walletAddress;
    let redmarsTokenInfo = await dispatch(redmarsTokenAction(walletType, data));

    if (redmarsTokenInfo) {
      let balance =
        redmarsTokenInfo?.redmarsTokenBalance /
        redmarsTokenInfo?.redmarsTokenDecimal;
      setRedmarsTokenBalance(balance);
      setRedmarsDecimals(redmarsTokenInfo?.redmarsTokenDecimal);
      setRedmarsSymbol(redmarsTokenInfo?.redmarsTokenSymbol);
    }
    // }
  };

  const addRemoveClass = () => {
    document.body.classList.add("project_page");
    return () => {
      document.body.classList.remove("project_page");
    };
  };

  const getTransactions = async () => {
    let page = localStorage.getItem("currentPage");
    let filter = {};
    filter.page = page;
    filter.limit = pageLimit;
    filter.userAddress = walletAddress;

    _getIcoRequestList(filter);
  };

  const onIt = async () => {
    let page;
    page = localStorage.getItem("currentPage");
    if (page == null) {
      localStorage.setItem("currentPage", 1);
    }
    page = localStorage.getItem("currentPage");
    let filter = {};
    filter.page = page; //activePage;
    filter.limit = pageLimit;
    filter.userAddress = walletAddress;
    _getIcoRequestList(filter);
  };
 
  const _getIcoRequestList = async (filter) => {
    let result = await dispatch(getIcoRequestList(filter));
    if (result && result.data.error == false) {
      // await setProjectInfo(result?.data?.data);
      _calculateInvestment(result);
    }
  };

  const _calculateInvestment = async (result) => {
    if (result.data.data.rows) {
      result.data.data.rows.forEach((project, index) => {
        let totalUserInvestmentRmars = 0;
        let totalUserInvestmentBNB = 0;
        let token_sold_toUser = 0;
        for (const investment of project.user_investment) {
          if (investment.token_type == 1) {
            totalUserInvestmentBNB =
              totalUserInvestmentBNB + investment.amount / 10 ** 18;
          } else {
            totalUserInvestmentRmars =
              totalUserInvestmentRmars + investment.amount / _redmarsDecimals;
          }

          token_sold_toUser =
            token_sold_toUser +
            parseInt(investment.token_sold) /
              parseInt(result.data.data.rows[index].token_decimal);
        }
        result.data.data.rows[index].token_sold_toUser = token_sold_toUser;
        result.data.data.rows[index].totalUserInvestmentBNB =
          totalUserInvestmentBNB;
        result.data.data.rows[index].totalUserInvestmentRmars =
          totalUserInvestmentRmars;
        // const now = Date.now();
        // result.data.data.rows[index].ico_start_time = now / 1000;
        // result.data.data.rows[index].duration = 8;
        // result.data.data.rows[index].fcfs_duration = 10;
        // result.data.data.rows[index].vesting_time = 15;
      });
      await setProjectInfo(result?.data?.data);
      getImg();
      global_list_checker_interval();
    }
  };

  const investToken = async (e) => {
    e.preventDefault();
    if (!walletAddress) {
      return toast.error("Please connect to the wallet.");
    }
    let data = {};
    data.projectId = projectData.project_id;
    data.walletAddress = walletAddress;
    data.selectedTokenType = selectedTokenType;
    let decimals = selectedTokenType == 1 ? 10 ** 18 : redmarsDecimals;

    data.bnb = CommonService.convertWithDecimal(bnb, decimals); // product of input with redmars decimal
    console.log("data -- >> ", data);
    console.log("selectedTokenType -- >> ", selectedTokenType);
    if (selectedTokenType == 2) {
      let balance = await dispatch(
        getBalance({ tokenAddress: REDMARS_TOKEN_ADDRESS, walletAddress })
      );
      console.log("balance 0 -- ", balance);
      console.log("amount --  0 -- ", data.bnb);

      if (parseInt(balance) < parseInt(data.bnb)) {
        return toast.error("Insufficient Redmars Balance.");
      }
      //for other token need to check allowance
      //check allowance

      let checkAllowance = await dispatch(
        toCheckAllowanceToBuyTokenAction(walletType, data)
      );

      if (Number(checkAllowance) <= Number(data.bnb)) {
        await dispatch(toApproveAllowanceToBuyTokenAction(walletType, data));
      }
    }

    let result = await dispatch(investTokenAction(walletType, data));
    if (result && result.status) {
      toast.success("Token Purchased successfully.");
      setBnb();
      //onIt();
      handleClose();
    }
  };

  const showBuyModal = (e, item) => {
    e.preventDefault();
    handleShow();
    setProjectData(item);
    setTokenType(2);
  };

  //calulate requested Tokens
  const convertedToken = async () => {
    if (bnb != null || bnb != undefined || bnb !== "") {
      let data = {};
      data.projectId = projectData?.project_id;
      let decimals = selectedTokenType == 1 ? 10 ** 18 : redmarsDecimals;
      data.bnb = CommonService.convertWithDecimal(bnb, decimals);
      data.tokenType = selectedTokenType;
      let result = await dispatch(calculateTokensAction(walletType, data));
      if (result) {
        setCalculatedToken(result[1]);
      } else {
        setCalculatedToken(0);
      }
    }
  };
  //claim user token functionality
  const claimUserToken = async (e, projectId) => {
    e.preventDefault();

    let data = {};
    data.walletAddress = walletAddress;
    data.projectId = projectId;
    let result = await dispatch(claimUserTokenAction(walletType, data));
    if (result && result.status) {
      toast.success("Claimed successfully.");
      onIt();
    }
  };

  // check status to create ico and invest tokens
  const checkIcoStatus = (item) => {
    let totalTime =
      Number(item.ico_start_time) +
      Number(item.duration) +
      Number(item?.fcfs_duration);
    if (
      moment(totalTime * 1000).isBefore(moment.utc()) ||
      Number(item.no_of_tokens_for_sale) === Number(item.no_of_tokens_sold) ||
      item?.is_active == 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  // Function to claim owner token
  const claimOwnerToken = async (e, projectId) => {
    let data = {};
    data.walletAddress = walletAddress;
    data.projectId = projectId;
    let result = await dispatch(toClaimMoneyAction(walletType, data));
    if (result && result.status) {
      toast.success("Owner claimed successfully.");
      onIt();
    }
  };

  const pageHandler = async (activePage) => {
    let filter = {};
    filter.page = activePage; //activePage;
    filter.limit = pageLimit;
    filter.userAddress = walletAddress;
    _getIcoRequestList(filter);

    setActivePage(activePage);
    localStorage.setItem("currentPage", activePage);
  };

const getImg = async()=>{

var reader = new FileReader();
    reader.onload = function() {
        var dataUrl = reader.result;
        var base64 = dataUrl.split(',')[1];
        console.log('base64 - ',base64)
        // callback(base64);
    };
    reader.readAsDataURL(projectInfo.rows[0].user_id_proof);
}

  return (
    <>
      <section className="project">
        <Container className="custom-width">
          <Row className="project_hd">
            <Col lg={6} md={12}>
              <MainHeading heading="Projects" />
            </Col>
            <Col lg={6} md={12}>
              <div className="seclt_pop">
                <span>
                  Redmars Balance :{" "}
                  {redmarsTokenBalance ? redmarsTokenBalance.toFixed(2) : 0}{" "}
                </span>
                <RequestPopup
                  callback={() => onIt()}
                  redmarsBalance={redmarsTokenBalance}
                  redmarsDecimal={redmarsDecimals}
                />
              </div>
            </Col>
          </Row>
          <Row>
            {projectInfo && projectInfo.count > 0 ? (
              projectInfo.rows.map((item, index) => (
                <>
                  <Col
                    key={index}
                    xl={4}
                    lg={6}
                    md={12}
                    className="project_block"
                  >
                    <div className="block_project">
                    <div className="check-col">
                      {checkIcoStatus(item) == false ? (
                        <>
                          <span className="badge bg-success">Active</span>
                        </>
                      ) : (
                        <>
                          <span className="badge bg-danger">InActive</span>
                        </>
                      )}
                          {item.kyc_approved?  <img src={kycimg}/>:''}
                            </div>
                      {item?.is_active == 1 && (
                        <Timer
                          type="icoEndsIn"
                          data={item}
                          vestedData={item}
                          refreshTimer={refreshTimer}
                        />
                      )}

                      <div className="list">
                        <>
                          <div className="_projectInfoData">
                            <ul>
                              <li>
                                <span>Price:</span>
                              </li>
                              <li>Project Name</li>
                              <li>Owner Name</li>
                              <li>Description</li>
                              <li>Token Name:</li>
                              <li>No. of Total Tokens:</li>
                              <li>No. of Sold Tokens:</li>
                              <li>Days:</li>
                              <li>FCFS Days:</li>
                              <li>Token Invest</li>
                              <li>Token Bought</li>
                            </ul>

                            <ul className="ml-3">
                              <li>
                                <span>
                                  {item?.token_price / busdDecimals}
                                  &nbsp; BUSD
                                </span>
                              </li>
                              <li>{item?.project_name}</li>
                              <li>{item?.project_owner_name}</li>
                              <li>{item?.project_description}</li>
                              <li>{item?.token_name}</li>
                              <li>
                                {item?.no_of_tokens_for_sale /
                                  item?.token_decimal}
                                &nbsp;
                                {item?.token_symbol}
                              </li>
                              <li>
                                {item?.no_of_tokens_sold / item?.token_decimal}
                                &nbsp;
                                {item?.token_symbol}
                              </li>
                              <li>
                                {CommonService.getDaysFromMilliSecond(
                                  item?.duration
                                )}
                              </li>
                              <li>
                                {CommonService.getDaysFromMilliSecond(
                                  item?.fcfs_duration
                                )}
                              </li>
                              <li>
                                {item.totalUserInvestmentRmars > 0
                                  ? item.totalUserInvestmentRmars + " RMARS"
                                  : ""}
                                {item.totalUserInvestmentRmars > 0 &&
                                item.totalUserInvestmentBNB > 0
                                  ? ", "
                                  : ""}

                                {item.totalUserInvestmentBNB > 0
                                  ? item.totalUserInvestmentBNB + " BNB "
                                  : ""}
                                {item.totalUserInvestmentRmars == 0 &&
                                item.totalUserInvestmentBNB == 0
                                  ? 0
                                  : ""}
                              </li>
                              <li>
                                {item.token_sold_toUser > 0 ? (
                                  <>{item.token_sold_toUser}</>
                                ) : (
                                  0
                                )}
                              </li>
                            </ul>
                          </div>
                        </>
                      </div>
                      <div className="feed_project">
                        {item?.is_active == 1 ? (
                          <Timer
                            type="vestedTimer"
                            data={item}
                            vestedData={item}
                            refreshTimer={refreshTimer}
                          />
                        ) : null}
                        {
                          walletAddress ? <BtnActions
                          data={item}
                          refreshTimer={refreshTimer}
                          showBuyModal={showBuyModal}
                          claimUserToken={claimUserToken}
                          claimOwnerToken={claimOwnerToken}
                        /> : ""
                        }
                        


                        <Modal show={show} onHide={handleClose} centered>
                          <Modal.Header closeButton>
                            <Modal.Title>Buy Token</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form>
                              {/* <div className="custom_select">
                                <Form.Label>RMARS</Form.Label>
                              </div> */}
                              <Form.Group
                                className="mb-3"
                                controlId="tokenNumbers"
                              >
                                <InputGroup className="mb-3">
                                  <FormControl
                                    type="text"
                                    placeholder="Enter Amount"
                                    autoComplete="off"
                                    value={bnb}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      if (value == "") {
                                        setBnb("");
                                      }
                                      let result =
                                        CommonService.allowOnlyNumber(value);
                                      if (result) {
                                        setBnb(e.target.value);
                                      }
                                    }}
                                  />
                                  <Form.Select
                                    className="form-control select-token"
                                    align="end"
                                    id="input-group-dropdown-2"
                                    value={selectedTokenType}
                                    onChange={(e) =>
                                      setTokenType(e.target.value)
                                    }
                                  >
                                    <option value="2" selected>
                                      RedMars
                                    </option>
                                    <option value="1">BNB</option>
                                  </Form.Select>
                                </InputGroup>

                                <p>
                                  Tokens :
                                  {bnb
                                    ? calulatedToken /
                                      projectData?.token_decimal
                                    : 0}
                                  &nbsp;
                                  {calulatedToken > 0
                                    ? projectData?.token_symbol
                                    : null}
                                </p>
                              </Form.Group>
                              <div className="pull-right">
                                <Button
                                  variant="primary"
                                  type="button"
                                  className="cm_btn"
                                  onClick={(e) => investToken(e)}
                                >
                                  Submit
                                </Button>
                              </div>
                            </Form>
                          </Modal.Body>
                        </Modal>
                      </div>
                    </div>
                  </Col>
                </>
              ))
            ) : (
              <span className="no-records">
                <center>No record found yet!</center>
              </span>
            )}
          </Row>
          {projectInfo && projectInfo.count > 0 ? (
            <Pagination
              totalPages={projectInfo.count / pageLimit}
              handler={(e) => pageHandler(e)}
              className="text-center"
              currentPage={localStorage.getItem("currentPage")}
            />
          ) : null}
        </Container>
      </section>
    </>
  );
};

export default Project;
