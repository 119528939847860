import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getLoginSession } from "../../../utils/session";
import {
  projectCounterAction,
  projectInfoAction,
  redmarsTokenAction,
  redmarsTokenDetailsAction,
  tokenDetailsAction,
} from "../../../redux/action/user.action";
import { CommonService } from "../../../utils/commonService";
import { toApproveIcoRequestAction } from "../../../redux/action/admin.action";
import { toast } from "../../../Components/Toast/Toast";
import moment from "moment";
import CopyClipboard from "../../../Components/CopyClipboard/CopyClipboard";
import Pagination from "../../../Components/Pagination";
import { getIcoRequestList } from "../../../redux/action/admin.action";
import {
  callContractSendMethod,
  approveICO
} from "../../../redux/action/contract.action";
import { NavLink, useNavigate } from "react-router-dom";
import Viewmodal from "./Viewmodal";


const ProjectRequest = () => {
  let globalList;
  const dispatch = useDispatch();
  const walletType = getLoginSession();
  const history = useNavigate();

  const userAddress = useSelector((state) =>
    state.connect.walletAddress ? state.connect.walletAddress : false
  );
  const walletAddress = useSelector((state) => state.connect.walletAddress);

  const [pageLimit, setPageLimit] = useState(12);
  const busdDecimals = useSelector((state) => state.redmars.busdDecimals);

  const [projectList, setProjectList] = useState([]);
  console.log('projectList', projectList)
  const [redmarsTokenBalance, setRedmarsTokenBalance] = useState();
  const [redmarsDecimals, setRedmarsDecimals] = useState();
  const [redmarsSymbol, setRedmarsSymbol] = useState();
  const [totalRecords, setTotalRecords] = useState();

  useEffect(() => {
    OnInit();
    getDecimals();
  }, []);

  const global_list_checker_interval = async () => {
    await clearInterval(globalList);
    globalList = setInterval(async function myTimer() {
      if (userAddress) {
        getTransactions();
      }
    }, 5000);
  };

  useEffect(async () => {
    if (userAddress) {
      global_list_checker_interval();
    }
  }, [userAddress]);

  useEffect(() => {
    return () => {
      clearInterval(globalList);
    };
  }, []);

  const getDecimals = async () => {
    let result = await dispatch(redmarsTokenDetailsAction(walletType));
    if (result) {
      let data = {};
      data.tokenAddress = result?.redMarsTokenAddress;
      data.walletAddress = userAddress;

      let redmarsTokenInfo = await dispatch(
        redmarsTokenAction(walletType, data)
      );

      if (redmarsTokenInfo) {
        let balance =
          redmarsTokenInfo?.redmarsTokenBalance /
          redmarsTokenInfo?.redmarsTokenDecimal;
        setRedmarsTokenBalance(balance);
        setRedmarsSymbol(redmarsTokenInfo?.redmarsTokenSymbol);
        setRedmarsDecimals(redmarsTokenInfo?.redmarsTokenDecimal);
      }
    }
  };

  const OnInit = async () => {
    let page;
    page = localStorage.getItem("adminCurrentPage");
    if (page == null) {
      localStorage.setItem("adminCurrentPage", 1);
    }
    page = localStorage.getItem("adminCurrentPage");
    let filter = {};
    filter.page = page;
    filter.limit = pageLimit;
    _getIcoRequestList(filter);
  };
  //Approve ICO handler
  const approveIcoHandler = async (e, projectId) => {
    e.preventDefault();
    let data = { walletAddress, projectId }
    let seekApproval = await dispatch(approveICO(data));
    if (seekApproval && seekApproval.status == true) {
      toast.success("ICO approved successfully");
      OnInit();
    }
  };

  const manageIcoStatus = (item) => {
    let status;
    let totalTime = Number(item?.ico_start_time) + Number(item?.duration) + (Number(item?.no_of_tokens_for_sale) > Number(item?.no_of_tokens_sold) ? Number(item?.fcfs_duration) : 0)

    if (item?.ico_start_time == 0 && item.is_active == 0) {
      status = <span className="badge bg-danger">In-Active</span>;
    } else {
      if (
        item.amount_raised == 0 &&
        moment(moment()).isAfter(totalTime * 1000) == true
      ) {
        status = <span className="badge bg-danger">ICO Ended</span>;
      }
      if (
        item.amount_raised > 0 &&
        moment(moment()).isAfter(totalTime * 1000) == true
      ) {
        status = <span className="badge bg-danger">ICO Ended</span>;
      }
      if (
        item.is_active == 1 &&
        item.no_of_tokens_for_sale == item.no_of_tokens_sold &&
        moment(totalTime * 1000).isBefore(moment.utc()) == false
      ) {
        status = <span className="badge bg-danger">ICO Ended</span>;
      }
      if (
        item.is_active == 1 &&
        item.no_of_tokens_for_sale !== item.no_of_tokens_sold &&
        moment(totalTime * 1000).isBefore(moment.utc()) == false
      ) {
        status = <span className="badge bg-success">Active</span>;
      }

      if (
        item.is_active == 0 &&
        item.no_of_tokens_for_sale == item.no_of_tokens_sold &&
        item.amount_raised > 0
      ) {
        status = <span className="badge bg-info">Claimed</span>;
      }
      if (
        item.is_active == 0 &&
        item.no_of_tokens_for_sale !== item.no_of_tokens_sold &&
        item.is_owner_claimed == 1
      ) {
        status = <span className="badge bg-info">Claimed</span>;
      }
    }
    return status;
  };
  const pageHandler = async (activePage) => {
    let filter = {};
    filter.page = Number(activePage);
    filter.limit = pageLimit;
    _getIcoRequestList(filter);
    localStorage.setItem("adminCurrentPage", activePage);
  };

  const getTransactions = async () => {
    let page = localStorage.getItem("adminCurrentPage");
    let filter = {};
    filter.page = page;
    filter.limit = pageLimit;
    _getIcoRequestList(filter);
  };


  const _getIcoRequestList = async (filter) => {
    let result = await dispatch(getIcoRequestList(filter));
    if (result && result.data.error == false) {
      setProjectList(result?.data?.data);
    }
  }




  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <div className="create-request"></div>
          <div className="table_box">
            <Table responsive hover className="mainTable requestTable">
              <thead>
                <tr>
                  <th>Project ID</th>
                  <th>Projet Name</th>
                  <th>Owner Name</th>
                  <th>Token For Sale</th>
                  <th>Token Price In BUSD</th>
                  {/* <th>Amount raised (RMARS)</th> */}
                  <th>Start Time</th>
                  <th>Duration</th>
                  <th>Token Sold</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {projectList.count ? (
                  projectList.rows.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.id}</td>
                      <td>{item?.project_name}</td>
                      <td>{item?.project_owner_name}</td>
                      <td>
                        {item?.no_of_tokens_for_sale / item?.token_decimal}{" "}
                        {item?.token_symbol}
                        &nbsp;
                      </td>
                      <td>
                        {item?.token_price / busdDecimals}
                        &nbsp; BUSD
                      </td>
                      <td>
                        {item?.ico_start_time > 0
                          ? moment(item?.ico_start_time * 1000).format(
                            "DD MMM ,YYYY hh:ss A"
                          )
                          : "--"}
                      </td>
                      <td>
                        {CommonService.getDaysFromMilliSecond(item?.duration)}
                        &nbsp;Days
                      </td>
                      <td>
                        {item?.no_of_tokens_sold / item?.token_decimal}
                        &nbsp;
                        {item?.token_symbol}
                      </td>


                      <td>{manageIcoStatus(item)}</td>
                      <td>
                        {Number(item?.ico_start_time) === 0 ? (
                          <Button
                            type="button"
                            className="cus-btn"
                            onClick={(e) => {
                              approveIcoHandler(e, item?.project_id);
                            }}
                          >
                            Approve
                          </Button>


                        ) : (
                          <span className="approveElseCondition">
                            <center>--</center>
                          </span>
                        )}
                        <Viewmodal  data = {item} />
                      </td>

                    </tr>
                  ))
                ) : (
                  <td className="text-center" colSpan="11">
                    No records found
                  </td>
                )}
              </tbody>
            </Table>
          </div>
        </Col>

      </Row>
      {projectList && projectList.count > 0 ? (
        <Pagination
          totalPages={projectList.count / pageLimit}
          handler={(e) => pageHandler(e)}
        />
      ) : null}
    </Container>
  );
};

export default ProjectRequest;
