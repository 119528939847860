import Web3 from "web3";
import RedmarsABI from "../Abis/RedmarsAbi.json";
import RedmarsStakingABI from "../Abis/StakingAbi.json";
import DynamicABI from "../Abis/DynamicAbi.json";
import BigNumber from "big-number";
import { CommonService } from "../utils/commonService";

import {
  REDMARS_CONTRACT_ADDRESS,
  REDMARS_STAKING_ADDRESS,
  REDMARS_TOKEN_ADDRESS,
} from "../constant";

let web3Instance, redmarsInstance, redmarsStakingInstance;

const callWeb3 = () => {
  return new Promise(async (resolve, reject) => {
    if (!web3Instance) {
      const { ethereum } = window;
      if (ethereum) {
        web3Instance = new Web3(ethereum);
      } else if (window.web3) {
        web3Instance = new Web3(window.web3.currentProvider);
      }
    }
    resolve(web3Instance);
  });
};

const calllobalFunction = async () => {
  let web3 = await callWeb3();
  redmarsInstance = new web3.eth.Contract(RedmarsABI, REDMARS_CONTRACT_ADDRESS);
  redmarsStakingInstance = new web3.eth.Contract(
    RedmarsStakingABI,
    REDMARS_STAKING_ADDRESS
  );
  return true;
};

calllobalFunction();

const createRedmarsInstance = async () => {
  return new Promise(async (resolve, reject) => {
    if (redmarsInstance) {
      resolve(redmarsInstance);
    } else {
      calllobalFunction()
        .then(() => {
          resolve(redmarsInstance);
        })
        .catch(reject);
    }
  });
};

const createRedmarsStakingInstance = async () => {
  return new Promise(async (resolve, reject) => {
    if (redmarsStakingInstance) {
      resolve(redmarsStakingInstance);
    } else {
      calllobalFunction()
        .then(() => {
          resolve(redmarsStakingInstance);
        })
        .catch(reject);
    }
  });
};

const createDynamicInstance = async (address) => {
  return new Promise(async (resolve, reject) => {
    let web3 = await callWeb3();
    let myDynamicContractInstance = await new web3.eth.Contract(
      DynamicABI,
      address
    );
    resolve(myDynamicContractInstance);
  });
};

const callContractGetMethod = async (method, data, type = "stake") => {
  return new Promise(async (resolve, reject) => {
    try {
      let contract;
      if (type === "launchpad") {
        contract = await createRedmarsInstance();
      } else {
        contract = await createRedmarsStakingInstance();
      }
      if (contract.methods) {
        let _parmsLength = data.length;
        if (_parmsLength) {
          switch (_parmsLength) {
            case 1:
              contract.methods[method](data[0])
                .call()
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            case 2:
              contract.methods[method](data[0], data[1])
                .call()
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            case 3:
              contract.methods[method](data[0], data[1], data[2])
                .call()
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            case 4:
              contract.methods[method](data[0], data[1], data[2], data[3])
                .call()
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            case 5:
              contract.methods[method](
                data[0],
                data[1],
                data[2],
                data[3],
                data[4]
              )
                .call()
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            default:
              contract.methods[method](data[0])
                .call()
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
          }
        } else {
          contract.methods[method]()
            .call()
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              reject(error);
            });
        }
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      reject(error);
    }
  });
};

const callContractSendMethod = async (
  method,
  data,
  walletAddress,
  type = "stake"
) => {
  console.log(method, data, walletAddress, type);

  return new Promise(async (resolve, reject) => {
    try {
      let contract;
      if (type === "launchpad") {
        contract = await createRedmarsInstance();
      } else {
        contract = await createRedmarsStakingInstance();
      }
      if (contract.methods) {
        let _parmsLength = data.length;
        let gasLimit;
        if (_parmsLength) {
          switch (_parmsLength) {
            case 1:
              console.log(contract.methods);
              gasLimit = await contract.methods[method](data[0]).estimateGas({
                from: walletAddress,
              });
              console.log(gasLimit);
              contract.methods[method](data[0])
                .send({ from: walletAddress, gasLimit })
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  console.log(error);
                  reject(error);
                });
              break;
            case 2:
              gasLimit = await contract.methods[method](
                data[0],
                data[1]
              ).estimateGas({ from: walletAddress });
              contract.methods[method](data[0], data[1])
                .send({ from: walletAddress, gasLimit })
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            case 3:
              gasLimit = await contract.methods[method](
                data[0],
                data[1],
                data[2]
              ).estimateGas({ from: walletAddress });
              contract.methods[method](data[0], data[1], data[2])
                .send({ from: walletAddress, gasLimit })
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            case 4:
              gasLimit = await contract.methods[method](
                data[0],
                data[1],
                data[2],
                data[3]
              ).estimateGas({ from: walletAddress });
              contract.methods[method](data[0], data[1], data[2], data[3])
                .send({ from: walletAddress, gasLimit })
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            case 5:
              gasLimit = await contract.methods[method](
                data[0],
                data[1],
                data[2],
                data[3],
                data[4]
              ).estimateGas({ from: walletAddress });
              contract.methods[method](
                data[0],
                data[1],
                data[2],
                data[3],
                data[4]
              )
                .send({ from: walletAddress, gasLimit })
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
              break;
            default:
              gasLimit = await contract.methods[method](
                data[0],
                data[1],
                data[2],
                data[3],
                data[4],
                data[5]
              ).estimateGas({ from: walletAddress });
              contract.methods[method](
                data[0],
                data[1],
                data[2],
                data[3],
                data[4],
                data[5]
              )
                .send({ from: walletAddress, gasLimit })
                .then((result) => {
                  resolve(result);
                })
                .catch((error) => {
                  reject(error);
                });
          }
        } else {
          gasLimit = await contract.methods[method]().estimateGas({
            from: walletAddress,
          });
          contract.methods[method]()
            .send({ from: walletAddress, gasLimit })
            .then((result) => {
              resolve(result);
            })
            .catch((error) => {
              reject(error);
            });
        }
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      reject(error);
    }
  });
};

const getTokenAllowanceInfo = async (
  walletAddress,
  tokenAddress,
  contractAddress
) => {
  const myDynamicContractInstance = await createDynamicInstance(tokenAddress);
  return new Promise(async (resolve, reject) => {
    try {
      if (myDynamicContractInstance.methods) {
        myDynamicContractInstance.methods
          .allowance(walletAddress, contractAddress)
          .call()
          .then((result) => {
            resolve(result);
          })
          .catch(reject);
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};

const getTokenAllowance = async (
  walletAddress,
  tokenAddress,
  contractAddress
) => {
  let maxlimit = BigNumber(10).power(40);
  maxlimit = maxlimit.toString();
  const myDynamicContractInstance = await createDynamicInstance(tokenAddress);
  return new Promise(async (resolve, reject) => {
    try {
      if (myDynamicContractInstance.methods) {
        myDynamicContractInstance.methods
          .approve(contractAddress, maxlimit)
          .send({ from: walletAddress })
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            console.log("error ", error);
            reject(error);
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};

const getDecimals = async (address) => {
  const contract = await createDynamicInstance(address);
  return new Promise(async (resolve, reject) => {
    try {
      if (contract.methods) {
        contract.methods
          .decimals()
          .call()
          .then((result) => {
            resolve(result);
          })
          .catch(reject);
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};

const stake = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await createRedmarsStakingInstance();
      if (contract.methods) {
        let allowance = await getTokenAllowanceInfo(
          data.walletAddress,
          REDMARS_TOKEN_ADDRESS,
          REDMARS_STAKING_ADDRESS
        );
        if (allowance < data.amount) {
          let allowanceRes = await getTokenAllowance(
            data.walletAddress,
            REDMARS_TOKEN_ADDRESS,
            REDMARS_STAKING_ADDRESS
          );
          if (!(allowanceRes && allowanceRes.status)) {
            return false;
          }
        }
        const gasLimit = await contract.methods
          .stake(data.amount)
          .estimateGas({ from: data.walletAddress });
        contract.methods
          .stake(data.amount)
          .send({ from: data.walletAddress, gasLimit })
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      reject(error);
    }
  });
};

const unstake = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await createRedmarsStakingInstance();
      if (contract.methods) {
        const gasLimit = await contract.methods
          .unstake(data.amount)
          .estimateGas({ from: data.walletAddress });
        contract.methods
          .unstake(data.amount)
          .send({ from: data.walletAddress, gasLimit })
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      reject(error);
    }
  });
};

const getBalance = async (data) => {
  const myDynamicContractInstance = await createDynamicInstance(
    data.tokenAddress
  );
  return new Promise(async (resolve, reject) => {
    try {
      if (myDynamicContractInstance.methods) {
        myDynamicContractInstance.methods
          .balanceOf(data.walletAddress)
          .call()
          .then((result) => {
            resolve(result);
          })
          .catch(reject);
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};

const requestICO = async (data) => {
    console.log('data:::::::::::::::::', data)
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await createRedmarsInstance();
      if (contract.methods) {
        let allowance = await getTokenAllowanceInfo(
          data.walletAddress,
          data.address,
          REDMARS_CONTRACT_ADDRESS
        );

        if (allowance == 0) {
          let allowanceRes = await getTokenAllowance(
            data.walletAddress,
            data.address,
            REDMARS_CONTRACT_ADDRESS
          );
          if (!(allowanceRes && allowanceRes.status)) {
            return false;
          }
        }
        let decimals = await getDecimals(data.address);
        const numOfToken = CommonService.convertWithDecimal(
          data.noOfToken,
          10 ** decimals
        );
        const gasLimit = await contract.methods
          .requestICO(
            data.projectId,
            numOfToken,
            data.tokenPrice,
            data.duration,
            data.fcfsDuration,
            data.vestingTime,
            data.address
            
          )
          .estimateGas({ from: data.walletAddress });

        contract.methods
          .requestICO(
            data.projectId,
            numOfToken,
            data.tokenPrice,
            data.duration,
            data.fcfsDuration,
            data.vestingTime,
            data.address
           
          )
          .send({ from: data.walletAddress, gasLimit })
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      reject(error);
    }
  });
};

const approveICO = async (data) => {
  console.log("data - ", data);
  return new Promise(async (resolve, reject) => {
    try {
      const contract = await createRedmarsInstance();
      if (contract.methods) {
        const gasLimit = await contract.methods
          .approveICO(data.projectId)
          .estimateGas({ from: data.walletAddress });
        contract.methods
          .approveICO(data.projectId)
          .send({ from: data.walletAddress, gasLimit })
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      reject(error);
    }
  });
};
export const ContractService = {
  callContractGetMethod,
  getDecimals,
  callContractSendMethod,
  stake,
  unstake,
  getBalance,
  requestICO,
  approveICO,
};
