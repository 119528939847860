import { NOMICS_DATA, PAGE_NUMBER } from "../action/user.action";

const initialState = {
  nomicsData: "",
  activePageNo: 1,
};

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case NOMICS_DATA:
      return { ...state, nomicsData: action.payload };
    case PAGE_NUMBER:
      return { ...state, activePageNo: action.payload };
    default:
      return state;
  }
}
