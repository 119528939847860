import React, { useEffect, useState } from "react";
import { CommonService } from "../../utils/commonService";
import "./Timer.scss";
import moment from "moment";


export default function Timer(props) {
  let timeLeftInterval, clearVestedInterval;
  const [timer, setTimer] = useState();
  const [vestingTimer, setVestingTimer] = useState();
  const [timerType, setTimerType] = useState('Ico');
  const [vestedTimerData, setVestedTimerData] = useState(props?.vestedData);
  const [time, setTime] = useState(moment.utc());
  // useEffect(() => {
  //   clearInterval(timeLeftInterval);
  //   clearInterval(clearVestedInterval);
  //   if (props.type === "icoEndsIn") {
  //     timeLeftInterval = setInterval(() =>
  //       calculateIcoEndTime(item), 1000);
  //   } else {
  //     clearVestedInterval = setInterval(
  //       () => calculateVestedTimeEndsIn(vestedTimerData),
  //       1000
  //     );
  //   }
  // }, []);

  useEffect(() => {
    if (props.type === "icoEndsIn") {
      calculateIcoEndTime(props.data)
    } else {
      setVestedTimerData(props?.vestedData)
      calculateVestedTimeEndsIn(props?.vestedData)
    }
  }, [time]);

  useEffect(() => {
    clearInterval(timeLeftInterval);

    timeLeftInterval = setInterval(() => {
      setTime(moment.utc())
    }, 1000);
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(timeLeftInterval);
    };
  }, []);

  const calculateIcoEndTime = (item) => {
    let timeleft;
    if (item) {
      let totalTime = Number(item?.ico_start_time) + Number(item?.duration);
      let totalTimeAfterFCFS = Number(item?.ico_start_time) + Number(item?.duration) + (Number(item?.no_of_tokens_for_sale) > Number(item?.no_of_tokens_sold) ? Number(item?.fcfs_duration) : 0);
      if (moment(totalTime * 1000).isBefore(moment.utc()) && moment(totalTimeAfterFCFS * 1000).isAfter(moment.utc()) && Number(item?.no_of_tokens_for_sale) > Number(item?.no_of_tokens_sold)) {
        totalTime = totalTimeAfterFCFS;
        setTimerType('FCFS')
      }
      const result = CommonService.getLeftTime(totalTime * 1000);
      let days = result.days;
      let hrs = result.hrs;
      let min = result.min;
      let sec = result.sec;

      if (days >= 0 &&
        hrs >= 0 &&
        min >= 0 &&
        sec >= 0) {
        if (days > 0) {
          timeleft = days + "d : " + hrs + "h : " + min + "m : " + sec + "s";
        } else if (hrs > 0) {
          timeleft = hrs + "h : " + min + "m : " + sec + "s";
        } else if (min > 0) {
          timeleft = min + "m : " + sec + "s";
        } else if (sec > 0) {
          timeleft = sec + "s";
        } else {
          timeleft = "Ended"
        }
      } else {
        timeleft = "Ended"
      }
    }
    setTimer(timeleft);
  };

  const calculateVestedTimeEndsIn = (vestedTimerData) => {
    if (vestedTimerData) {
      let item = vestedTimerData;
      let timeleft;

      let totalTime = Number(item?.ico_start_time) + Number(item?.duration) + (Number(item?.no_of_tokens_for_sale) > Number(item?.no_of_tokens_sold) ? Number(item?.fcfs_duration) : 0);
      let totalVestingTime = Number(item?.ico_start_time) + Number(item?.duration) + (Number(item?.no_of_tokens_for_sale) > Number(item?.no_of_tokens_sold) ? Number(item?.fcfs_duration) : 0) + Number(item?.vesting_time);
      if (moment(totalTime * 1000).isBefore(moment.utc()) && moment(totalVestingTime * 1000).isAfter(moment.utc()) && (item?.totalUserInvestmentBNB > 0 || item?.totalUserInvestmentRmars > 0)) {
        const result = CommonService.getLeftTime(totalVestingTime * 1000);
        let days = result.days;
        let hrs = result.hrs;
        let min = result.min;
        let sec = result.sec;
        if (days >= 0 &&
          hrs >= 0 &&
          min >= 0 &&
          sec >= 0) {
          if (days > 0) {
            timeleft = days + "d : " + hrs + "h : " + min + "m : " + sec + "s";
          } else if (hrs > 0) {
            timeleft = hrs + "h : " + min + "m : " + sec + "s";
          } else if (min > 0) {
            timeleft = min + "m : " + sec + "s";
          } else if (sec > 0) {
            timeleft = sec + "s";
          } else {
            timeleft = "Ended"
          }
        } else {
          timeleft = "Ended"
        }
        setVestingTimer(timeleft);
      } else {
        setVestingTimer('');
      }
    } else {
      setVestingTimer('');
    }

  };

  const checkIfIcoEnded = () => {
    if (timer !== undefined) {
      if (
        timer === "Ended"
      ) {
        return true;
      } else {
        return false;
      }
    }
    // else {
    //   console.log('666- ')
    //   return true;
    // }
  };

  const checkIfVestingTimeEnded = () => {
    if (vestedTimerData) {
      let item = vestedTimerData;

      let totalTime = Number(item?.ico_start_time) + Number(item?.duration) + (Number(item?.no_of_tokens_for_sale) > Number(item?.no_of_tokens_sold) ? Number(item?.fcfs_duration) : 0);
      let totalVestingTime = Number(item?.ico_start_time) + Number(item?.duration) + (Number(item?.no_of_tokens_for_sale) > Number(item?.no_of_tokens_sold) ? Number(item?.fcfs_duration) : 0) + Number(item?.vesting_time);
      if (moment(totalTime * 1000).isBefore(moment.utc()) && moment(totalVestingTime * 1000).isAfter(moment.utc()) && Number(item?.no_of_tokens_sold) > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

  };
  return (
    <div>
      {props?.type == "icoEndsIn" ? (
        <>
          {props && props.data && props.data.is_active == 1 ? (
            <>
              <span className={"heading_span"}>
                {checkIfIcoEnded() == true ? "ICO Ended" : `${timerType} Ends In`}
              </span>
              &nbsp;&nbsp;
              {checkIfIcoEnded() == true ? "" : <span className={"timer_span"}>
                {timer}
              </span>}

            </>
          ) : (
            <span className={"heading_span"}>
              ICO Ended
            </span>
          )}
        </>
      ) : (
        null
      )}

      {props && props?.type == "vestedTimer" ? (
        <>
          {
            props && props.vestedData && props.vestedData.is_active == 1 ? (
              <>
                <span className={"heading_span"}>
                  {checkIfVestingTimeEnded() == true
                    ? 'Vesting period ends in'
                    : ""}
                </span>
                &nbsp;&nbsp;
                <span className={"timer_span"}>
                  {checkIfVestingTimeEnded() == true ? (
                    <>
                      {`${vestingTimer}`}
                    </>
                  ) : ''}
                </span>
              </>
            ) : null
            // <span className={"timer_span"}>
            //   Vesting Time Ended : 0 days: 0 hrs: 0 min: 0 sec
            // </span>
          }
        </>
      ) : null}
    </div>
  );
}
