import React, { Component } from "react";
import { connect } from "react-redux";
import { Spinner } from 'react-bootstrap'

import "./Loader.scss"
class LoaderComponent extends Component {
	render() {
		if (this.props.loading) {
			return (
				<div className="overlayloader">
					<Spinner animation="border" variant="light" />
				</div>
			);
		} else {
			return <div></div>;
		}
	}
}


const mapStateToProps = (state) => {
	return {
		loading: state.loading.loading,
	};
};

export default connect(mapStateToProps)(LoaderComponent);
