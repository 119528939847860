import React, { useState, useEffect } from "react";
import { Modal, Form } from 'react-bootstrap';
import search from '../../../Assets/images/search.svg'
import Buttoncustom from "../../../Components/Buttoncustom/Buttoncustom";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CommonService } from "../../../utils/commonService";
import { toast } from "../../../Components/Toast/Toast";
import {
    stake,
    unstake
} from "../../../redux/action/contract.action";
import './Home.scss';
import { TIER_TYPE_ENUM } from '../../../constant';

const Stakemodal = (props) => {
    const dispatch = useDispatch();
    const [amount, setAmount] = useState();
    const walletAddress = useSelector((state) => state.connect.walletAddress);
    const redmarsDecimals = useSelector((state) => state.redmars.redmarsDecimals);
    const busdDecimals = useSelector((state) => state.redmars.busdDecimals);

    const stakeUnstake = async (action, all) => {
        if (!amount && !all) {
            toast.error("Please enter a valid amount");
            return false;
        }
        let _amount = await CommonService.convertWithDecimal(amount, redmarsDecimals);
        let data = {
            walletAddress,
            amount: _amount
        }

        if (action === "stake") {
            dispatch(stake(data)).then(async (res) => {
                if (res && res.status) {
                    toast.success("Staked successfully");
                    setAmount('');
                    props.onHide();
                    props.getUserDetails();
                }
            })
        } else {
            if (all) {
                data.amount = props.userStakingInfo._userStakedAmount
            }
            dispatch(unstake(data)).then(async (res) => {
                if (res && res.status) {
                    toast.success("Unstaked buy successfully");
                    setAmount('');
                    props.onHide();
                    props.getUserDetails();
                }
            })
        }
    }


    return (
        <>

            <Modal className="stake-modal" size="lg" show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="mr-4">
                            <p>TIER</p>
                            <h3>{props.userStakingInfo && props.userStakingInfo._userStakedAmount > 0 ? props.currentTier ? TIER_TYPE_ENUM[props.currentTier - 1] : 'NONE' : 'NONE'}</h3>
                        </div>
                        <div className="mr-4">
                            <p>STAKED</p>
                            <h3>{props.userStakingInfo ? props.userStakingInfo._userStakedAmount / redmarsDecimals : 0} <small>$RMARS</small>

                            </h3>
                        </div>
                        <div className="mr-4">
                            <p>BALANCE</p>
                            <h3> {(props.balance / redmarsDecimals).toFixed(4)} <small>$RMARS</small>

                            </h3>
                        </div>
                        <div>
                            <p>ALLOCATION</p>
                            <h3> $ {(props.userAllocationValue / busdDecimals).toFixed(4)}

                            </h3>
                        </div>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-5">
                    <Form>
                        <Form.Group className="mb-3 form_input">
                            <Form.Control min="0" type="number" placeholder="Enter the amount of RMARS" value={amount} onChange={(e) => setAmount(e.target.value)} />
                            <img className="search-icon" src={search} alt="img" />
                            {props.tierList && props.tierList.length ? <p className="text-center mt-2 op-5">Minimum  {props.tierList[0].stakeAmount / redmarsDecimals} $RMARS for stake

                            </p> : ''}
                        </Form.Group>
                        <div className="text-center mt-4 mb-5">
                            <Buttoncustom className="me-3" buttontext="Stake" onClick={(e) => stakeUnstake('stake')} />
                            <Buttoncustom className="mars_btn " buttontext="Unstake" onClick={(e) => stakeUnstake('unstake')} />
                        </div>
                    </Form>
                    <div className="text-center mt-4 mb-5 " >
                        <a className="text-white" role="button" onClick={(e) => stakeUnstake('unstake', true)}>UNSTAKE ALL</a>
                    </div>
                    <ul className="stake-modal-list">
                        {props.tierList ? props.tierList.map((item, index) => (
                            <li>{TIER_TYPE_ENUM[index]}</li>
                        )) : ''}
                    </ul>
                </Modal.Body>

            </Modal>
        </>
    )
}
export default Stakemodal